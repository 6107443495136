import { Container, Form, FormCheck } from 'react-bootstrap';
import React, { useCallback, useEffect, useState } from 'react';
import { useNavigate, useParams } from 'react-router-dom';
import { Formik } from 'formik';
import { FormattedMessage } from 'react-intl';
import { showBackButton } from '../../../redux/appSlice';
import {
   InventoryForm,
   newInventoryObject,
   useValidationHandler,
} from '../../../forms/InventoryForm';
import { loadLegoSet } from '../../../redux/pagesSlice';
import { ButtonPanel } from '../../../components/molecules';
import { Inventory, LegoSet } from '../../../types/api';
import { useAppDispatch } from '../../../hooks';
import { InventoryModel } from '../../../models/InventoryModel';
import { LegoSetModel } from '../../../models/LegoSetModel';
import { SubmitButton } from '../../../components/forms';
import { PageTitle } from '../../../components/organisms/PageTitle';
import { convertToNumber } from '../../../utils';

export const EditInventoryMobilePage = () => {
   const routeParams = useParams<{ number?: string; id?: string }>();
   const navigate = useNavigate();
   const dispatch = useAppDispatch();
   const handleValidate = useValidationHandler();
   const [createAnotherEntry, setCreateAnotherEntry] = useState(false);
   const [legoSet, setLegoSet] = useState<LegoSet | null>(null);
   const [inventory, setInventory] = useState<Partial<Inventory>>(newInventoryObject);

   useEffect(() => {
      dispatch(showBackButton(true));
   });

   useEffect(() => {
      (async () => {
         const inventoryId = convertToNumber(routeParams.id);

         const legoSets = await LegoSetModel.list({ number: routeParams.number });
         // Das angegebene LegoSet existiert nicht, also hier abbrechen
         if (legoSets.length === 0) return;

         setLegoSet(legoSets[0] ?? null);

         if (inventoryId === 0) {
            // Es soll ein neues Inventory anlegen
            setInventory(newInventoryObject);
         } else {
            // Ein bestehendes Inventory soll bearbeitet werden
            const loadedInventory = await InventoryModel.get(inventoryId);
            if (!loadedInventory) return;

            setInventory(loadedInventory);
         }
      })();
   }, [routeParams.id, routeParams.number]);

   const handleSubmitForm = useCallback(
      async (values: Partial<Inventory>) => {
         if (!legoSet) return;

         if (!values.id) {
            values.set_id = legoSet.id;
            await InventoryModel.insert(values);
         } else {
            await InventoryModel.update(values);
         }

         dispatch(loadLegoSet(values.set_id));

         if (createAnotherEntry) {
            // Zurücksetzen des Inventars, sodass es erneut gespeichert werden kann
            // Aber wir übernehmen ein paar Eigenschaften
            setInventory({
               ...newInventoryObject,
               set_id: legoSet.id,
               type: values.type,
               date: values.date,
               price: values.price,
               quality: values.quality,
               storage_location: values.storage_location,
               comment: values.comment,
            });
         } else {
            navigate(-1);
         }
      },
      [createAnotherEntry, dispatch, legoSet, navigate]
   );

   return (
      <Container>
         <PageTitle title={`${legoSet?.number ?? ''}: ${legoSet?.name ?? ''}`} hidden />
         <Formik
            onSubmit={handleSubmitForm}
            initialValues={inventory}
            validate={handleValidate}
            enableReinitialize
         >
            {formik => (
               <Form
                  noValidate
                  onSubmit={e => {
                     e.preventDefault();
                     formik.handleSubmit();
                  }}
               >
                  <InventoryForm legoSet={legoSet} formik={formik} />
                  <ButtonPanel className="mt-4">
                     {!formik.values.id && (
                        <FormCheck>
                           <FormCheck.Input
                              id="create-another-entry"
                              type="checkbox"
                              name="enabled"
                              checked={createAnotherEntry}
                              onChange={(e: any) => setCreateAnotherEntry(e.target.checked)}
                           />
                           <FormCheck.Label htmlFor="create-another-entry">
                              <FormattedMessage
                                 id="dialog.edit-inventory.create-another-entry"
                                 defaultMessage="Weiteren Eintrag erstellen"
                              />
                           </FormCheck.Label>
                        </FormCheck>
                     )}
                     <SubmitButton formik={formik}>
                        <FormattedMessage id="button.save" defaultMessage="Speichern" />
                     </SubmitButton>
                  </ButtonPanel>
               </Form>
            )}
         </Formik>
      </Container>
   );
};
