import React, { useCallback } from 'react';
import { GoogleLogin, GoogleLoginResponse, GoogleLoginResponseOffline } from 'react-google-login';
import { toast } from 'react-toastify';
import config from '../../utils/config';
import { UserModel } from '../../models/UserModel';
import { useMemorizedIntl, useSession } from '../../hooks';

/**
 * Creates a sign in button for google accounts.
 */
export const GoogleSignInButton = () => {
   const intl = useMemorizedIntl();
   const { signIn, signOut } = useSession();

   const handleLogin = useCallback(
      async (response: GoogleLoginResponse | GoogleLoginResponseOffline) => {
         const { tokenId } = response as GoogleLoginResponse;
         if (tokenId) {
            try {
               const loggedInUser = await UserModel.authenticate(tokenId);
               await signIn(loggedInUser);
            } catch (error) {
               toast.error(
                  intl.formatMessage({
                     id: 'error.login-failed',
                     defaultMessage:
                        'Es gab ein Fehler beim Anmelden. Bitte versuche es später erneut.',
                  })
               );
               signOut();
            }
         }
      },
      [intl, signIn, signOut]
   );

   const handleLoginFailure = useCallback(
      (response: any) => {
         // eslint-disable-next-line no-console
         console.warn(response);
         toast.error(
            intl.formatMessage({
               id: 'error.login-failed',
               defaultMessage: 'Es gab ein Fehler beim Anmelden. Bitte versuche es später erneut.',
            })
         );
      },
      [intl]
   );

   const loadFinished = useCallback(
      async (successLogin: boolean) => {
         if (!successLogin) {
            try {
               // Wir versuchen, ohne Token über Session-Cookie ein Login durchzuführen
               const loggedInUser = await UserModel.authenticate();
               await signIn(loggedInUser);
            } catch {
               signOut();
            }
         } else {
            signOut();
         }
      },
      [signIn, signOut]
   );

   return (
      <div>
         <GoogleLogin
            theme="dark"
            clientId={config.googleClientId}
            buttonText={intl.formatMessage({
               id: 'button.login',
               defaultMessage: 'Anmelden',
            })}
            onSuccess={handleLogin}
            onFailure={handleLoginFailure}
            cookiePolicy="single_host_origin"
            responseType="code,token"
            isSignedIn
            onAutoLoadFinished={loadFinished}
         />
      </div>
   );
};
