import { Navigate, RouteObject } from 'react-router-dom';
import React, { useEffect, useState } from 'react';
import { RequireAuth } from './utils/RequireAuth';
import { WelcomePage } from './pages';
import { BlogPage } from './pages/blog';
import { ChangelogPage } from './pages/changelog';
import { ImprintPage } from './pages/imprint';
import { FaqPage } from './pages/faq';
import { EditInventoryMobilePage } from './pages/legoSets/[number]/EditInventoryMobilePage';
import { LegoSetChangeRequestPage } from './pages/legoSets/[number]/LegoSetChangeRequestPage';
import { LegoSetPage } from './pages/legoSets/[numberAndName]';
import { InventoryPicturesMobilePage } from './pages/legoSets/[number]/InventoryPicturesMobilePage';
import { EditSaleMobilePage } from './pages/legoSets/[number]/EditSaleMobilePage';
import { MyInventoryPage } from './pages/inventory/[inventoryType]';
import { LegoSetsByCategoryPage } from './pages/category';
import { LegoSetsByLabelPage } from './pages/label';
import { ProfilePage } from './pages/user/ProfilePage';
import { CompleteRegistrationPage } from './pages/user/CompleteRegistrationPage';
import { UserFiltersEditPage } from './pages/inventory/search/manage/[id]';
import { BlogPostEditPage } from './pages/blog/BlogPostEditPage';
import { MoreMobilePage } from './pages/more';
import { SearchPage } from './pages/search';
import { LegoSet, ROLE_ADMIN } from './types/api';
import { BlogPostShowPage } from './pages/blog/BlogPostShowPage';
import { OverviewAdminPage } from './pages/admin';
import { UserEditAdminPage } from './pages/admin/users/[id]';
import { UsersAdminPage } from './pages/admin/users';
import { LegoSetChangeRequestsAdminPage } from './pages/admin/legoSets/changeRequests';
import { LegoSetImportsAdminPage } from './pages/admin/imports';
import { CategoriesAdminPage } from './pages/admin/categories';
import { LabelsAdminPage } from './pages/admin/labels';
import { PageNotFoundPage } from './pages/PageNotFoundPage';
import { LegoSetEditAdminPage } from './pages/admin/legoSets/[id]';
import { CategoryEditAdminPage } from './pages/admin/categories/[id]';
import { LabelEditAdminPage } from './pages/admin/labels/[id]';
import { LegoSetsAdminPage } from './pages/admin/legoSets';
import { UserFiltersPage } from './pages/inventory/search/manage';
import { Statistics2Page } from './pages/stats/Statistics2Page';
import { StatisticsPage } from './pages/stats';
import { LegoSetChangeRequestEditAdminPage } from './pages/admin/legoSets/changeRequests/[id]';
import { MyFiltersPage } from './pages/inventory/search';
import { useIdFromParams } from './hooks';
import { LegoSetModel } from './models/LegoSetModel';
import { pageLinks } from './utils';
import { ContentLoader } from './components/atoms';
import { LegoSetsPage } from './pages/legoSets';

const NavigateToLegoSets = () => {
   const legoSetId = useIdFromParams();
   const [legoSet, setLegoSet] = useState<LegoSet | null>(null);

   useEffect(() => {
      LegoSetModel.get(legoSetId).then(setLegoSet);
   }, [legoSetId]);

   if (!legoSet) return <ContentLoader />;

   return <Navigate to={pageLinks.legoSetDetail(legoSet)} replace />;
};

export interface RouteDef {
   path?: string;
   element: JSX.Element;
   allowPublicAccess?: boolean;
   neededRoles?: string[];
   fallbackElement?: JSX.Element;
   children?: RouteDef[];
}

export const routesDefinition: RouteDef[] = [
   /* public pages */
   {
      path: '/',
      allowPublicAccess: true,
      element: <WelcomePage />,
   },
   {
      path: '/imprint',
      allowPublicAccess: true,
      element: <ImprintPage />,
   },
   {
      path: '/changelog',
      allowPublicAccess: true,
      element: <ChangelogPage />,
   },
   {
      path: '/faq',
      allowPublicAccess: true,
      element: <FaqPage />,
   },
   /* Blog */
   {
      path: '/blog',
      allowPublicAccess: true,
      element: <BlogPage />,
   },
   {
      path: '/blog/:idAndSubject',
      allowPublicAccess: true,
      element: <BlogPostShowPage />,
   },
   {
      path: '/blog/new',
      neededRoles: [ROLE_ADMIN],
      element: <BlogPostEditPage />,
   },
   {
      path: '/blog/:id/edit',
      neededRoles: [ROLE_ADMIN],
      element: <BlogPostEditPage />,
   },
   /* Lego-Sets */
   {
      path: '/legoSets',
      element: <LegoSetsPage />,
   },
   {
      path: '/legoSets/:numberAndName',
      element: <LegoSetPage />,
   },
   {
      path: '/legoSets/:number/inventory/:id',
      element: <EditInventoryMobilePage />,
   },
   {
      path: '/legoSets/:number/inventory/:id/pictures',
      element: <InventoryPicturesMobilePage />,
   },
   {
      path: '/legoSets/:number/inventory/:id/editSale',
      element: <EditSaleMobilePage />,
   },
   {
      path: '/legoSets/:number/requestChange',
      element: <LegoSetChangeRequestPage />,
   },
   /* Inventory */
   {
      path: '/inventory/:inventoryType',
      element: <MyInventoryPage />,
   },
   {
      path: '/inventory/search',
      element: <MyFiltersPage />,
   },
   {
      path: '/inventory/search/manage',
      element: <UserFiltersPage />,
   },
   {
      path: '/inventory/search/manage/:id',
      element: <UserFiltersEditPage />,
   },
   /* Category & Label */
   {
      path: '/category',
      element: <LegoSetsByCategoryPage />,
      children: [
         {
            path: ':name',
            element: <LegoSetsByCategoryPage />,
         },
      ],
   },
   {
      path: '/label',
      element: <LegoSetsByLabelPage />,
      children: [
         {
            path: ':name',
            element: <LegoSetsByLabelPage />,
         },
      ],
   },
   {
      path: '/stats',
      element: <StatisticsPage />,
   },
   {
      path: '/stats/old',
      neededRoles: [ROLE_ADMIN],
      element: <Statistics2Page />,
   },
   {
      path: '/user/profile',
      element: <ProfilePage />,
   },
   {
      path: '/user/registration',
      element: <CompleteRegistrationPage />,
   },
   {
      path: '/search/:query',
      element: <SearchPage />,
   },
   {
      path: '/more',
      element: <MoreMobilePage />,
   },
   {
      path: '/admin',
      neededRoles: [ROLE_ADMIN],
      element: <OverviewAdminPage />,
   },
   {
      path: '/admin/users',
      neededRoles: [ROLE_ADMIN],
      element: <UsersAdminPage />,
   },
   {
      path: '/admin/users/:id',
      neededRoles: [ROLE_ADMIN],
      element: <UserEditAdminPage />,
   },
   {
      path: '/admin/legoSets',
      neededRoles: [ROLE_ADMIN],
      element: <LegoSetsAdminPage />,
   },
   {
      path: '/admin/legoSets/:id',
      neededRoles: [ROLE_ADMIN],
      element: <LegoSetEditAdminPage />,
   },
   {
      path: '/admin/legoSets/changeRequests',
      neededRoles: [ROLE_ADMIN],
      element: <LegoSetChangeRequestsAdminPage />,
   },
   {
      path: '/admin/legoSets/changeRequests/:id',
      neededRoles: [ROLE_ADMIN],
      element: <LegoSetChangeRequestEditAdminPage />,
   },
   {
      path: '/admin/categories',
      neededRoles: [ROLE_ADMIN],
      element: <CategoriesAdminPage />,
   },
   {
      path: '/admin/categories/:id',
      neededRoles: [ROLE_ADMIN],
      element: <CategoryEditAdminPage />,
   },
   {
      path: '/admin/labels',
      neededRoles: [ROLE_ADMIN],
      element: <LabelsAdminPage />,
   },
   {
      path: '/admin/labels/:id',
      neededRoles: [ROLE_ADMIN],
      element: <LabelEditAdminPage />,
   },
   {
      path: '/admin/imports',
      neededRoles: [ROLE_ADMIN],
      element: <LegoSetImportsAdminPage />,
   },
   // Redirects for former routes, remove later
   {
      path: '/user/notifications',
      element: <Navigate to="/user/profile" replace />,
   },
   {
      path: '/inventory',
      element: <Navigate to="/inventory/bought" replace />,
   },
   {
      path: '/wanted',
      element: <Navigate to="/inventory/wanted" replace />,
   },
   {
      path: '/bought',
      element: <Navigate to="/inventory/bought" replace />,
   },
   {
      path: '/sales',
      element: <Navigate to="/inventory/sold" replace />,
   },
   {
      path: '/sets/:id',
      element: <NavigateToLegoSets />,
   },
   // Fallback
   {
      path: '*',
      element: <PageNotFoundPage />,
   },
];

const createRouteObject = (routeDef: RouteDef): RouteObject => {
   const getElement = (): React.ReactNode => {
      if (!routeDef.allowPublicAccess || routeDef.fallbackElement || routeDef.neededRoles) {
         return (
            <RequireAuth
               neededRoles={routeDef.neededRoles}
               publicElement={routeDef.fallbackElement}
            >
               {routeDef.element}
            </RequireAuth>
         );
      }

      return routeDef.element;
   };

   return {
      path: routeDef.path,
      element: getElement(),
      children: routeDef.children?.map(createRouteObject),
   };
};

const routes: RouteObject[] = routesDefinition.map(createRouteObject);

export default routes;
