import { BaseModel } from './BaseModel';
import { ImageUpload } from '../types/api';
import ApiService from '../services/ApiService';
import { IApiResponse } from '../types/IApiResponse';

class ImageUploadModelClass extends BaseModel<ImageUpload> {
   public getName(): string {
      return 'image_upload';
   }

   public async insert(obj: Partial<ImageUpload>): Promise<ImageUpload> {
      throw new Error('Do not call this method');
   }

   public async insertImage(obj: Partial<ImageUpload>, file: File): Promise<ImageUpload> {
      const formData = new FormData();
      formData.append('imageFile', file, file.name);
      Object.entries(obj).forEach(kv => {
         formData.append(kv[0], String(kv[1]));
      });

      const options = {
         headers: {
            'Content-Type': 'multipart/form-data',
         },
      };

      // HTTP-Fehler werden hier von dem Aufrufer selber behandelt um genauere Fehlermeldungen anzuzeigen
      const res = await ApiService.http.post<IApiResponse<ImageUpload>>(
         'data/image_upload',
         formData,
         options
      );
      return res.data.data[0];
   }
}
export const ImageUploadModel = new ImageUploadModelClass();
