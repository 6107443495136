import React, { useEffect, useMemo, useState } from 'react';
import { Formik, FormikErrors } from 'formik';
import { Form, Modal, ProgressBar } from 'react-bootstrap';
import { FormattedMessage } from 'react-intl';
import { components, NoticeProps } from 'react-select';
import AsyncSelect from 'react-select/async';
import groupBy from 'lodash/groupBy';
import { isMobile } from 'react-device-detect';
import { mdiAlertCircleOutline } from '@mdi/js';
import Icon from '@mdi/react';
import { ButtonPanel, LinkButton, ResponsiveButton } from '../molecules';
import { useCache, useMemorizedIntl, useValidationErrors } from '../../hooks';
import { FormUtils, GenericControl, SubmitButton } from '../forms';
import { LegoSetModel } from '../../models/LegoSetModel';
import ApiService from '../../services/ApiService';
import { IApiResponse } from '../../types/IApiResponse';
import { getSelectStyles } from '../../utils';
import { SetImportModel } from '../../models/SetImportModel';
import { LegoSet, SetImport, SetImportStatusType } from '../../types/api';
import { LegoSetCard } from '../organisms/LegoSetCard';
import { LegoSetWithStats } from '../../types/api/LegoSetWithStats';
import { Colors } from '../atoms';

interface FormModel {
   legoSet: LegoSetSuggestion | null;
   category_id: number;
}

interface LegoSetSuggestion {
   id: string;
   name: string;
   number: string;
   type: 'set' | 'minifigure' | 'gear';
}

interface Props {
   children: (props: { onPress: () => void }) => React.ReactNode;
}

/**
 * Shows a dialog to add a new lego set based on its number by Lego.
 */
export const AddLegoSetDialog = (props: Props) => {
   const intl = useMemorizedIntl();
   const validationErrors = useValidationErrors();
   const { categories } = useCache();
   const [showDialog, setShowDialog] = useState(false);
   const [step, setStep] = useState<'enterNumber' | 'submitted' | SetImportStatusType>(
      'enterNumber'
   );
   const [legoSet, setLegoSet] = useState<LegoSetWithStats | null>(null);

   // Step zurücksetzen
   useEffect(() => {
      setStep('enterNumber');
   }, [showDialog]);

   const typeTranslations = useMemo(
      () =>
         ({
            set: intl.formatMessage({
               id: 'dialog.add-lego-set.type.set',
               defaultMessage: 'LEGO-Set',
            }),
            minifigure: intl.formatMessage({
               id: 'dialog.add-lego-set.type.minifigure',
               defaultMessage: 'Minifigur',
            }),
            gear: intl.formatMessage({
               id: 'dialog.add-lego-set.type.set',
               defaultMessage: 'LEGO-Set',
            }),
         } as { [key: string]: string }),
      [intl]
   );

   const loadLegoSetsSuggestion = async (
      inputValue: string
   ): Promise<{ label: string; options: LegoSetSuggestion[] }[]> => {
      if (!inputValue) return [];

      try {
         const result = (
            await ApiService.http.get<IApiResponse<LegoSetSuggestion>>(
               `search/${encodeURIComponent(inputValue)}`
            )
         ).data.data.filter(d => d.type !== 'gear');

         return Object.entries(groupBy(result, 'type')).map(([group, items]) => ({
            label: typeTranslations[group],
            options: items,
         }));
      } catch (error) {
         ApiService.handleError(error);
         return Promise.reject(error);
      }
   };

   const modalTitle = useMemo(() => {
      switch (step) {
         case 'error':
            return intl.formatMessage({
               id: 'dialog.add-lego-set.title.error',
               defaultMessage: 'Fehler bei der Anlage',
            });
         case 'success':
            return intl.formatMessage({
               id: 'dialog.add-lego-set.title.success',
               defaultMessage: 'LEGO-Set erfolgreich angelegt',
            });
         default:
            return intl.formatMessage({
               id: 'dialog.add-lego-set.title',
               defaultMessage: 'Neues LEGO-Set anlegen',
            });
      }
   }, [intl, step]);

   return (
      <>
         {props.children({ onPress: () => setShowDialog(true) })}
         <Modal show={showDialog} onHide={() => setShowDialog(false)} centered={isMobile} size="lg">
            <Modal.Header closeButton>
               <Modal.Title>{modalTitle}</Modal.Title>
            </Modal.Header>

            <Formik
               onSubmit={async values => {
                  // Zuerst legen wir den Import an.
                  const createdImport = await SetImportModel.insert({
                     number: values.legoSet?.number,
                     category_id: values.category_id,
                  });

                  setStep('submitted');

                  // Nun starten wir direkt den Import
                  try {
                     const importResult = (
                        await ApiService.http.get<
                           IApiResponse<{ importData: SetImport; legoSet: LegoSet | null }>
                        >(
                           `data/${SetImportModel.getName()}/${
                              createdImport.id
                           }/execute?preselectedId=${values.legoSet?.id}`
                        )
                     ).data.data[0];

                     if (importResult.legoSet) {
                        setLegoSet(
                           (
                              await ApiService.http.get<IApiResponse<LegoSetWithStats>>(
                                 `inventory/byLegoSets/${importResult.legoSet.id}`
                              )
                           ).data.data[0]
                        );
                     }

                     setStep(importResult.importData.status);
                  } catch (error) {
                     setStep('error');

                     ApiService.handleError(error);
                     return Promise.reject(error);
                  }
               }}
               initialValues={
                  {
                     legoSet: null,
                     category_id: 0,
                  } as FormModel
               }
               validate={async values => {
                  const errors: FormikErrors<FormModel> = {};

                  if (!values.legoSet) errors.legoSet = validationErrors.required;
                  if (!values.category_id) errors.category_id = validationErrors.required;

                  if (!errors.legoSet) {
                     const existingLegoSets = await LegoSetModel.list({
                        number: values?.legoSet?.number?.trim() ?? '',
                     });
                     if (existingLegoSets.length > 0)
                        errors.legoSet = validationErrors.legoSetAlreadyExist;
                  }

                  return errors;
               }}
            >
               {formik => (
                  <Form
                     noValidate
                     onSubmit={e => {
                        e.preventDefault();
                        formik.handleSubmit();
                     }}
                  >
                     <Modal.Body>
                        {step === 'enterNumber' && (
                           <>
                              <GenericControl
                                 formik={formik}
                                 name="legoSet"
                                 label={intl.formatMessage({
                                    id: 'dialog.add-lego-set.number.title',
                                    defaultMessage: 'Set-Nummer oder Suchbegriff',
                                 })}
                                 helpText={
                                    <Form.Text className="text-muted">
                                       <FormattedMessage
                                          id="dialog.add-lego-set.number.text"
                                          defaultMessage="Gebe entweder die Nummer des LEGO-Sets ein (z.B. 42070) oder ein Teil des Names des Sets."
                                       />
                                    </Form.Text>
                                 }
                              >
                                 <AsyncSelect
                                    loadOptions={loadLegoSetsSuggestion}
                                    getOptionValue={o => o.id}
                                    formatOptionLabel={o => `${o.number}: ${o.name}`}
                                    placeholder={intl.formatMessage({
                                       id: 'dialog.add-lego-set.number.placeholder',
                                       defaultMessage: 'Setnummer',
                                    })}
                                    styles={getSelectStyles(FormUtils.isInvalid(formik, 'legoSet'))}
                                    onChange={value => {
                                       formik.setFieldValue('legoSet' as string, value);
                                    }}
                                    onBlur={() => formik.handleBlur('legoSet')}
                                    isDisabled={formik.isSubmitting}
                                    components={{
                                       NoOptionsMessage,
                                       LoadingMessage,
                                    }}
                                    cacheOptions
                                    defaultOptions
                                 />
                              </GenericControl>
                              <GenericControl
                                 formik={formik}
                                 name="category_id"
                                 label={intl.formatMessage({
                                    id: 'dialog.add-lego-set.category.title',
                                    defaultMessage: 'Kategorie',
                                 })}
                                 helpText={
                                    <Form.Text className="text-muted">
                                       <FormattedMessage
                                          id="dialog.add-lego-set.category.text"
                                          defaultMessage="Deine Kategorie fehlt? Schreib ein E-Mail an {email}."
                                          values={{
                                             email: (
                                                <a href="mailto:info@brickfolio.de">
                                                   info@brickfolio.de
                                                </a>
                                             ),
                                          }}
                                       />
                                    </Form.Text>
                                 }
                              >
                                 <Form.Control
                                    as="select"
                                    name="category_id"
                                    value={formik.values.category_id}
                                    onChange={formik.handleChange}
                                    onBlur={formik.handleBlur}
                                    isInvalid={FormUtils.isInvalid(formik, 'category_id')}
                                    disabled={formik.isSubmitting}
                                 >
                                    <option value={0} disabled>
                                       {intl.formatMessage({
                                          id: 'form.option.please-select',
                                          defaultMessage: '-- Bitte auswählen --',
                                       })}
                                    </option>
                                    {categories.map(c => (
                                       <option key={c.id} value={c.id}>
                                          {c.name}
                                       </option>
                                    ))}
                                 </Form.Control>
                              </GenericControl>
                           </>
                        )}
                        {step === 'submitted' && <LoadingContent />}
                        {(step === 'success' || step === 'duplicate') && legoSet && (
                           <SuccessContent legoSet={legoSet} onClick={() => setShowDialog(false)} />
                        )}
                        {step === 'error' && <ErrorContent />}
                     </Modal.Body>

                     <Modal.Footer>
                        <ButtonPanel>
                           <ResponsiveButton
                              variant="secondary"
                              onClick={() => setShowDialog(false)}
                              disabled={formik.isSubmitting}
                           >
                              {step === 'enterNumber' || step === 'submitted' ? (
                                 <FormattedMessage id="button.cancel" defaultMessage="Abbrechen" />
                              ) : (
                                 <FormattedMessage id="button.close" defaultMessage="Schließen" />
                              )}
                           </ResponsiveButton>
                           <SubmitButton
                              formik={formik}
                              hidden={step !== 'enterNumber' && step !== 'submitted'}
                           >
                              <FormattedMessage id="button.next" defaultMessage="Weiter" />
                           </SubmitButton>
                        </ButtonPanel>
                     </Modal.Footer>
                  </Form>
               )}
            </Formik>
         </Modal>
      </>
   );
};

const NoOptionsMessage = (props: NoticeProps<LegoSetSuggestion>) => (
   <components.NoOptionsMessage {...props}>
      <FormattedMessage id="react-select.no-option" defaultMessage="Keine Ergebnisse" />
   </components.NoOptionsMessage>
);

const LoadingMessage = (props: NoticeProps<LegoSetSuggestion>) => (
   <components.LoadingMessage {...props}>
      <FormattedMessage id="react-select.loading" defaultMessage="Laden..." />
   </components.LoadingMessage>
);

const LoadingContent = () => (
   <>
      <ProgressBar className="my-2" animated now={100} />
      <div className="mt-4 h4 text-center">
         <FormattedMessage
            id="dialog.add-lego-set.waiting-for-import"
            defaultMessage="Bitte warte, bis das LEGO-Set mit all seinen Stammdaten angelegt ist."
         />
      </div>
   </>
);

const SuccessContent = ({
   legoSet,
   onClick,
}: {
   legoSet: LegoSetWithStats;
   onClick: () => void;
}) => (
   <div className="d-flex flex-column align-items-center">
      {isMobile ? (
         <div className="w-100">
            <LegoSetCard legoSet={legoSet} />
         </div>
      ) : (
         <div style={{ width: '18rem' }}>
            <LegoSetCard legoSet={legoSet} onClick={onClick} />
         </div>
      )}
      <LinkButton className="mt-3" to={`sets/${legoSet.id}`} onClick={onClick}>
         <FormattedMessage
            id="dialog.add-lego-set.go-to-lego-set"
            defaultMessage="Gehe zum LEGO-Set"
         />
      </LinkButton>
   </div>
);

const ErrorContent = () => (
   <div className="d-flex flex-column align-items-center">
      <Icon path={mdiAlertCircleOutline} color={Colors.danger} size={4} />
      <span className="mt-4 h4 text-center">
         <FormattedMessage
            id="dialog.add-lego-set.error-on-import"
            defaultMessage="Bei der Anlegen des LEGO-Sets trat ein Fehler auf!"
         />
      </span>
      <span className="text-center">
         <FormattedMessage
            id="dialog.add-lego-set.error-on-import.contact"
            defaultMessage="Sollte wiederholt ein Fehler auftreten, so wende dich an {email}."
            values={{
               email: <a href="mailto:info@brickfolio.de">info@brickfolio.de</a>,
            }}
         />
      </span>
   </div>
);
