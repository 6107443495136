import { Col, Row } from 'react-bootstrap';
import orderBy from 'lodash/orderBy';
import React, { useMemo } from 'react';
import flatten from 'lodash/flatten';
import { useLocation } from 'react-router-dom';
import { isMobile } from 'react-device-detect';
import { notEmpty } from '../../../utils';
import { LegoSetCard } from '../LegoSetCard';
import { useAppSelector } from '../../../hooks';
import { defaultOrderBy, LocationState, SearchAndFilterBar } from './SearchAndFilterBar';
import { InventoryType, LegoSet } from '../../../types/api';

import './LegoSetsGridList.scss';

interface Props {
   inventoryType?: InventoryType;
   hideOrdering?: boolean;
   lockedCategoryId?: number;
   onRefresh?: () => Promise<void>;
}

export const LegoSetsGridList = (props: Props) => {
   const location = useLocation();
   const { filter, sortBy, groupByLegoSet } = (location.state as LocationState) ?? {
      filter: {},
      sortBy: defaultOrderBy,
      groupByLegoSet: true,
   };
   const { entities: legoSets, loading } = useAppSelector(s => s.pages.inventoryByLegoSet);

   const inventory = useMemo(() => {
      if (!legoSets || groupByLegoSet) return [];

      // Wandelt LegoSets mit 1-n Inventories zu n Inventories um
      return flatten(legoSets.map(s => s.inventory)).filter(notEmpty);
   }, [groupByLegoSet, legoSets]);

   const filterLegoSets = (set: LegoSet) =>
      (!filter?.text ||
         set.number.toLowerCase().indexOf(filter?.text.toLowerCase()) >= 0 ||
         set.name.toLowerCase().indexOf(filter?.text.toLowerCase()) >= 0) &&
      (!filter?.categoryId || set.category_id === filter?.categoryId) &&
      (!filter?.legoStatus ||
         set.status_by_lego?.toLowerCase() === filter?.legoStatus.toLowerCase());

   const getSortByProperty = (key: string) => {
      switch (key) {
         case 'name':
         case 'number':
            return groupByLegoSet ? `${key}` : `legoSet.${key}`;
         case 'profit':
         case 'profitPercentage':
            return groupByLegoSet
               ? `statistics.${props.inventoryType}.${key}`
               : `statistics.${key}`;
         case 'date':
            return groupByLegoSet ? `statistics.${props.inventoryType}.date` : 'date';
         default:
            return key;
      }
   };

   return (
      <>
         <SearchAndFilterBar
            inventoryType={props.inventoryType}
            hideOrdering={props.hideOrdering}
            lockedCategoryId={props.lockedCategoryId}
            onRefresh={props.onRefresh}
         />
         {loading && <ListLoader showAsGrid={groupByLegoSet} inventoryType={props.inventoryType} />}
         <Row>
            {groupByLegoSet
               ? orderBy(legoSets, getSortByProperty(sortBy.property), sortBy.asc ? 'asc' : 'desc')
                    .filter(filterLegoSets)
                    .map(s => (
                       <Col
                          key={s.id}
                          xs={12}
                          sm={isMobile ? 12 : 6}
                          lg={isMobile ? 12 : 4}
                          xl={isMobile ? 12 : 3}
                       >
                          <LegoSetCard displayContext={props.inventoryType} legoSet={s} />
                       </Col>
                    ))
               : orderBy(inventory, getSortByProperty(sortBy.property), sortBy.asc ? 'asc' : 'desc')
                    .filter(inv => filterLegoSets(inv.legoSet))
                    .map(i => (
                       <Col key={i.id} xs={12}>
                          <LegoSetCard inventory={i} displayContext="inventory" />
                       </Col>
                    ))}
         </Row>
      </>
   );
};

interface ListLoaderProps extends Pick<Props, 'inventoryType'> {
   showAsGrid?: boolean;
}
const ListLoader = ({ showAsGrid = false, inventoryType }: ListLoaderProps) => {
   if (showAsGrid)
      return (
         <Row>
            {[...Array(8).keys()].map(i => (
               <Col
                  key={i}
                  xs={12}
                  sm={isMobile ? 12 : 6}
                  lg={isMobile ? 12 : 4}
                  xl={isMobile ? 12 : 3}
               >
                  <LegoSetCard displayContext={inventoryType} />
               </Col>
            ))}
         </Row>
      );

   return (
      <>
         {[...Array(8).keys()].map(i => (
            <LegoSetCard key={i} displayContext={inventoryType} />
         ))}
      </>
   );
};
