import React, { useEffect, useState } from 'react';
import { Badge, Container, ListGroup } from 'react-bootstrap';
import orderBy from 'lodash/orderBy';
import { Link, useParams } from 'react-router-dom';
import Icon from '@mdi/react';
import { mdiChevronRight } from '@mdi/js/commonjs/mdi';
import { LegoSetsGridList } from '../../components/organisms/LegoSetsGridList';
import { showBackButton } from '../../redux/appSlice';
import { useAppDispatch, useAppSelector, useMemorizedIntl } from '../../hooks';
import { Label } from '../../types/api';
import { LabelModel } from '../../models/LabelModel';
import { getInventoryByLabel } from '../../redux/pagesSlice';
import { Colors, ContentLoader } from '../../components/atoms';
import { PageTitle } from '../../components/organisms/PageTitle';
import { pageLinks } from '../../utils';

export const LegoSetsByLabelPage = () => {
   const intl = useMemorizedIntl();
   const dispatch = useAppDispatch();
   const [label, setLabel] = useState<Label | null>(null);

   const routeParams = useParams<{ name?: string }>();
   const allLabels = useAppSelector(s => s.data.labels);

   useEffect(() => {
      (async () => {
         if (!routeParams.name) {
            // Anzeigen aller Labels
            dispatch(showBackButton(false));
            setLabel(null);
         } else {
            // Lade Sets des angegebenen Labels
            dispatch(showBackButton(true));
            setLabel((await LabelModel.list({ name_key: routeParams.name }))[0]);
         }
      })();
   }, [routeParams.name, dispatch]);

   useEffect(() => {
      (async () => {
         if (!label) return;

         dispatch(getInventoryByLabel(label.id));
      })();
   }, [dispatch, label]);

   if (!label && routeParams.name) return <ContentLoader />;

   if (!label) {
      return (
         <Container>
            <PageTitle
               title={intl.formatMessage({
                  id: 'label.title.all',
                  defaultMessage: 'Alle Labels',
               })}
            />
            <ListGroup>
               {orderBy(allLabels, 'name')
                  .filter(l => l.lego_sets.length + l.lego_sets_by_user.length > 0)
                  .map(l => (
                     <ListGroup.Item
                        key={l.id}
                        as={Link}
                        to={pageLinks.legoSetsByLabel(l)}
                        className="d-flex flex-row align-items-center pe-0"
                        action
                     >
                        <span className="flex-fill">{l.name}</span>
                        <Badge className="badge-lg" pill bg="secondary">
                           {l.lego_sets.length + l.lego_sets_by_user.length}
                        </Badge>
                        <div className="d-flex align-items-center px-1">
                           <Icon path={mdiChevronRight} color={Colors.link} size={1} />
                        </div>
                     </ListGroup.Item>
                  ))}
            </ListGroup>
         </Container>
      );
   }

   return (
      <Container>
         <PageTitle
            title={intl.formatMessage(
               {
                  id: 'label.headline',
                  defaultMessage: 'Sets mit dem Label: {name}',
               },
               {
                  name: label.name,
               }
            )}
            appTitle={label.name}
            subtitle={intl.formatMessage({
               id: 'label.title',
               defaultMessage: 'Label',
            })}
         />
         <LegoSetsGridList hideOrdering />
      </Container>
   );
};
