import React, { useEffect, useState } from 'react';
import { Container, ListGroup } from 'react-bootstrap';
import { Link } from 'react-router-dom';
import Icon from '@mdi/react';
import { mdiChevronRight } from '@mdi/js/commonjs/mdi';
import { ComboChart } from '../../components/organisms';
import { Colors } from '../../components/atoms';
import { getAdminStats } from '../../utils/ModelUtils';
import { PageTitle } from '../../components/organisms/PageTitle';
import { useAppDispatch } from '../../hooks';
import { showBackButton } from '../../redux/appSlice';

const tableDefinition: { [key: string]: { title: string; to?: string } } = {
   user: {
      title: 'Benutzer',
      to: '/admin/users',
   },
   lego_set: {
      title: 'Lego-Sets',
      to: '/admin/legoSets',
   },
   category: {
      title: 'Kategorien',
      to: '/admin/categories',
   },
   label: {
      title: 'Labels',
      to: '/admin/labels',
   },
   inventory: {
      title: 'Inventar',
   },
   sold_set: {
      title: 'Verkaufte Sets',
   },
   image_upload: {
      title: 'Bilder',
   },
   set_import: {
      title: 'Importe',
      to: '/admin/imports',
   },
   lego_set_change_request: {
      title: 'Änderungsanträge',
      to: '/admin/legoSets/changeRequests',
   },
};

export const OverviewAdminPage = () => {
   const dispatch = useAppDispatch();
   const [stats, setStats] = useState<[string, number][]>([]);

   useEffect(() => {
      (async () => {
         dispatch(showBackButton(true));
         setStats(await getAdminStats());
      })();
   }, [dispatch]);

   return (
      <Container>
         <PageTitle title="Administration" />
         <ListGroup>
            {stats.map(([table, count]) => (
               <ListGroup.Item
                  key={table}
                  as={Link}
                  to={tableDefinition[table]?.to ?? '/admin'}
                  className="d-flex flex-row"
                  action={!!tableDefinition[table]?.to}
               >
                  <div className="flex-fill d-flex flex-column">
                     <h4 style={{ wordBreak: 'break-word' }}>{tableDefinition[table]?.title}</h4>
                     <small className="text-muted">
                        {count} {count === 1 ? 'Eintrag' : 'Einträge'}
                     </small>
                  </div>
                  <ComboChart
                     url={`admin/historyChart/${table}`}
                     theme="mini"
                     height="4rem"
                     width="10rem"
                     unit=""
                     options={{
                        colors: [Colors.primary],
                     }}
                  />
                  {tableDefinition[table]?.to ? (
                     <div className="d-flex align-items-center">
                        <Icon path={mdiChevronRight} color={Colors.link} size={1} />
                     </div>
                  ) : (
                     <div style={{ marginRight: '1.3125rem' }} />
                  )}
               </ListGroup.Item>
            ))}
         </ListGroup>
      </Container>
   );
};
