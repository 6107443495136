import React, { useEffect, useState } from 'react';
import { useLocation, useNavigate, useNavigationType, useRoutes } from 'react-router-dom';
import { BrowserView, isMobile, MobileView } from 'react-device-detect';
import { toast, ToastContainer } from 'react-toastify';
import { AxiosError } from 'axios';
import moment from 'moment';
import { NavBar } from './components/organisms/NavBar';
import { PageFooter } from './components/organisms/PageFooter';
import {
   importMessages,
   IntlProvider,
   LocaleMessages,
   SupportedLocales,
   SupportedLocalesType,
} from './intlHelpers';
import { setApiError, setChangeRequests, setLanguage, setNewsBadge } from './redux/appSlice';
import { AppTitle } from './app/AppTitle';
import { AppBottomNav } from './app/AppBottomNav';
import { MissingPublicNameBanner } from './components/organisms/MissingPublicNameBanner';
import { useAppDispatch, useAppSelector, useMemorizedIntl, useSession } from './hooks';
import routes, { routesDefinition } from './Routes';
import { UserModel } from './models/UserModel';
import ApiService from './services/ApiService';

import 'moment/locale/de';

const App = () => {
   const dispatch = useAppDispatch();
   const stateLanguage = useAppSelector(s => s.app.language);
   const [browserLanguages] = useState(navigator.languages);
   const sessionUser = useAppSelector(s => s.app.session.user);
   const [messages, setMessages] = React.useState<LocaleMessages | null>(null);

   useEffect(() => {
      if (window.screen.orientation && typeof window.screen.orientation.lock === 'function') {
         window.screen.orientation.lock('portrait').catch(e => console.log(e));
      }
   }, []);

   useEffect(() => {
      // Set Up error handler for API
      ApiService.uiErrorHandler = (error: AxiosError) => {
         dispatch(setApiError(error));
      };
   }, [dispatch]);

   useEffect(() => {
      const preferredLanguage = localStorage.getItem('language');
      const supportedLanguages = browserLanguages.filter(l =>
         Object.keys(SupportedLocales).includes(l)
      );
      if (preferredLanguage === null && supportedLanguages.length > 0) {
         const lang = supportedLanguages[0]; // Wir benutzen die Erste
         localStorage.setItem('language', lang);

         if (lang && stateLanguage !== lang) {
            dispatch(setLanguage(lang as SupportedLocalesType));
            return; // Da wir die Sprache geändert haben, wird das hier eh nochmal ausgeführt
         }
      } else if (preferredLanguage && preferredLanguage !== stateLanguage) {
         dispatch(setLanguage(preferredLanguage as SupportedLocalesType));
         return; // Da wir die Sprache geändert haben, wird das hier eh nochmal ausgeführt
      }

      moment.locale(stateLanguage ?? 'en');
      importMessages(stateLanguage ?? 'en').then(setMessages);
   }, [dispatch, browserLanguages, stateLanguage]);

   useEffect(() => {
      // Für mobile Geräte setzen wir die Textgröße etwas kleiner, sodass wir mehr Inhalt auf den Bildschirm bekommen.
      // Alles andere (Größe der Komponenten, Abstände, etc.) werden entsprechend auch kleiner/größer
      if (isMobile) {
         document.documentElement.style.fontSize = '12px';
      } else {
         document.documentElement.style.fontSize = '';
      }
   });

   useEffect(() => {
      if (sessionUser === null) return;

      const updateNotifications = async () => {
         try {
            const notifications = await UserModel.getNotifications();
            dispatch(setNewsBadge(notifications.newBlogPost ? 1 : 0));
            dispatch(setChangeRequests(notifications.legoSetChangeRequests));
         } catch (error) {
            // eslint-disable-next-line no-console
            console.warn('Error getting notifications.', error);
         }
      };

      const id = setInterval(updateNotifications, 1000 * 60); // Every 60 seconds
      (() => updateNotifications())(); // Einmal aufrufen, damit das sofort gemacht wird

      return () => clearInterval(id);
   }, [sessionUser, dispatch]);

   const element = useRoutes(routes);

   if (messages === null) return null;

   return (
      <IntlProvider
         locale={stateLanguage}
         messages={messages}
         defaultRichTextElements={{
            sup: chunks => <sup>{chunks}</sup>,
            strong: chunks => <strong>{chunks}</strong>,
            em: chunks => <em>{chunks}</em>,
         }}
      >
         <HttpErrorToast />
         <ScrollToTop />
         <div id="page-container">
            <BrowserView renderWithFragment>
               <NavBar />
            </BrowserView>
            <MobileView renderWithFragment>
               <AppTitle />
            </MobileView>

            <div id="content" className={isMobile ? 'mobile' : ''}>
               <MissingPublicNameBanner />
               <CompleteRegistrationRedirect />
               {element}
            </div>
            <BrowserView renderWithFragment>
               <PageFooter />
            </BrowserView>
            <MobileView renderWithFragment>
               <AppBottomNav />
            </MobileView>
         </div>
         <ToastContainer
            position="top-center"
            autoClose={5000}
            hideProgressBar={false}
            newestOnTop
            closeOnClick
            rtl={false}
            pauseOnFocusLoss
            draggable
            pauseOnHover
            theme="dark"
         />
      </IntlProvider>
   );
};

export default App;

const HttpErrorToast = () => {
   const intl = useMemorizedIntl();
   const dispatch = useAppDispatch();
   const apiError = useAppSelector(s => s.app.apiError);

   useEffect(() => {
      if (apiError) {
         // eslint-disable-next-line no-console
         console.log(apiError);
         toast.error(
            intl.formatMessage({
               id: 'http-error',
               defaultMessage: 'Leider trat ein Fehler auf. Bitte versuche es später erneut.',
            })
         );
         dispatch(setApiError(null));
      }
   }, [apiError, dispatch, intl]);

   return null;
};

const ScrollToTop = () => {
   const navigationType = useNavigationType();

   useEffect(() => {
      if (navigationType === 'PUSH') window.scrollTo(0, 0);
   }, [navigationType]);

   return null;
};

const CompleteRegistrationRedirect = () => {
   const navigate = useNavigate();
   const location = useLocation();
   const { sessionUser } = useSession();

   useEffect(() => {
      const isPublicRoute = routesDefinition.some(
         r => r.allowPublicAccess && r.path === location.pathname
      );

      if (
         sessionUser &&
         !sessionUser.registration_completed &&
         location.pathname !== '/user/registration' &&
         !isPublicRoute
      ) {
         navigate('/user/registration', { replace: true });
      }
   }, [navigate, sessionUser, location]);

   return null;
};
