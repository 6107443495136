import { Form, Modal } from 'react-bootstrap';
import { Formik } from 'formik';
import React, { useCallback, useEffect, useState } from 'react';
import { FormattedMessage } from 'react-intl';
import { ConfirmDeleteDialog } from './ConfirmDeleteDialog';
import { loadLegoSet } from '../../redux/pagesSlice';
import { ColoredValue, ContentLoader } from '../atoms';
import { handleSubmit, SoldSetForm, useValidationHandler } from '../../forms/SoldSetForm';
import { ButtonPanel, ResponsiveButton } from '../molecules';
import { Inventory, SoldSet } from '../../types/api';
import { SoldSetModel } from '../../models/SoldSetModel';
import { useAppDispatch, useSession } from '../../hooks';
import { InventoryModel } from '../../models/InventoryModel';
import { formatDate } from '../../utils';
import { SubmitButton } from '../forms';

interface Props {
   inventory: Partial<Inventory>;
   show: boolean;
   onClose: () => void;
}

export const EditSaleDialog = (props: Props) => {
   const dispatch = useAppDispatch();
   const { sessionUser } = useSession();
   const handleValidate = useValidationHandler();
   const [showDeleteDialog, setShowDeleteDialog] = useState(false);
   const [soldSet, setSoldSet] = useState<Partial<SoldSet>>({});
   const [isLoading, setLoading] = useState(true);

   useEffect(() => {
      (async () => {
         setLoading(true);
         const list = await SoldSetModel.list({
            inventory_id: props.inventory.id,
            user_id: sessionUser?.id ?? 0,
         });
         setSoldSet(list[0] ?? {});
         setLoading(false);
      })();
   }, [props.inventory, sessionUser]);

   const handleSubmitForm = useCallback(
      async (values: Partial<SoldSet>) => {
         await handleSubmit(values, props.inventory);

         props.onClose();
      },
      [props]
   );

   const handleDelete = useCallback(async () => {
      await SoldSetModel.delete(soldSet);

      await InventoryModel.update({
         ...props.inventory,
         type: 'bought',
      });
      dispatch(loadLegoSet(props.inventory.set_id));

      setShowDeleteDialog(false);
   }, [dispatch, props.inventory, soldSet]);

   return (
      <>
         <Modal show={props.show} onHide={props.onClose} size="lg">
            <Formik
               onSubmit={handleSubmitForm}
               initialValues={soldSet}
               enableReinitialize
               validate={handleValidate}
            >
               {formik => (
                  <Form
                     noValidate
                     onSubmit={e => {
                        e.preventDefault();
                        formik.handleSubmit();
                     }}
                  >
                     <Modal.Header closeButton>
                        <Modal.Title>
                           {!formik.values.id ? (
                              <FormattedMessage
                                 id="dialog.edit-sale.title.new"
                                 defaultMessage="Set verkaufen"
                              />
                           ) : (
                              <FormattedMessage
                                 id="dialog.edit-sale.title.edit"
                                 defaultMessage="Verkauf bearbeiten"
                              />
                           )}
                        </Modal.Title>
                     </Modal.Header>

                     <Modal.Body>
                        {isLoading ? <ContentLoader /> : <SoldSetForm formik={formik} />}
                     </Modal.Body>

                     <Modal.Footer>
                        <ButtonPanel>
                           <ResponsiveButton
                              variant="danger"
                              onClick={() => {
                                 props.onClose();
                                 setShowDeleteDialog(true);
                              }}
                              disabled={formik.isSubmitting}
                              hidden={!formik.values.id}
                           >
                              <FormattedMessage id="button.delete" defaultMessage="Löschen" />
                           </ResponsiveButton>
                           <ResponsiveButton variant="secondary" onClick={props.onClose}>
                              <FormattedMessage id="button.close" defaultMessage="Schließen" />
                           </ResponsiveButton>
                           <SubmitButton formik={formik}>
                              <FormattedMessage id="button.save" defaultMessage="Speichern" />
                           </SubmitButton>
                        </ButtonPanel>
                     </Modal.Footer>
                  </Form>
               )}
            </Formik>
         </Modal>
         <ConfirmDeleteDialog
            show={showDeleteDialog}
            onClose={() => setShowDeleteDialog(false)}
            onDelete={handleDelete}
         >
            <p>
               <FormattedMessage
                  id="dialog.delete-sale.text"
                  defaultMessage="Möchtest du den Verkauf vom {date} in Höhe von {price} wirklich löschen?"
                  values={{
                     date: formatDate(soldSet.date, 'L'),
                     price: <ColoredValue type="currency" value={soldSet.price} />,
                  }}
               />
            </p>
         </ConfirmDeleteDialog>
      </>
   );
};
