import React from 'react';
import { Colors } from './Colors';

interface Props {
   orientation?: 'vertical' | 'horizontal';
}

/**
 * Creates a vertical spacer for {@link Card}.
 */
export const CardSpacer = ({ orientation = 'vertical' }: Props) => (
   <div
      className={`${orientation === 'vertical' ? 'mx-2' : 'my-2'} align-self-stretch`}
      style={{
         width: orientation === 'vertical' ? 1 : 'auto',
         height: orientation === 'horizontal' ? 1 : 'auto',
         backgroundColor: Colors.secondary,
      }}
   />
);
