import React from 'react';
import ReactDOM from 'react-dom';
import './assets/css/bootstrap.theme.min.css';
import './assets/css/bootstrap-datepicker.css';
import './assets/css/bootstrap.overwrites.css';
import 'react-toastify/dist/ReactToastify.min.css';
import 'react-datepicker/dist/react-datepicker.css';
import 'react-mde/lib/styles/css/react-mde-all.css';
import 'react-querybuilder/dist/query-builder.scss';
import 'react-medium-image-zoom/dist/styles.css';
import 'react-loading-skeleton/dist/skeleton.css';
import './index.scss';
import { Provider } from 'react-redux';
import { BrowserRouter } from 'react-router-dom';
import { registerLocale, setDefaultLocale } from 'react-datepicker';
import de from 'date-fns/locale/de';
import en from 'date-fns/locale/en-GB';
import App from './App';
import * as serviceWorker from './serviceWorker';
import store from './redux';

registerLocale('de', de);
registerLocale('en', en);
setDefaultLocale('en');

ReactDOM.render(
   <React.StrictMode>
      <Provider store={store}>
         <BrowserRouter
            basename={process.env.NODE_ENV === 'development' ? '' : process.env.PUBLIC_URL}
         >
            <App />
         </BrowserRouter>
      </Provider>
   </React.StrictMode>,
   document.getElementById('root')
);

// If you want your app to work offline and load faster, you can change
// unregister() to register() below. Note this comes with some pitfalls.
// Learn more about service workers: https://bit.ly/CRA-PWA
serviceWorker.unregister();
