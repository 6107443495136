import { FormikErrors, FormikProps } from 'formik';
import React, { useCallback } from 'react';
import { Col, Container, Form, Row } from 'react-bootstrap';
import { FormattedMessage } from 'react-intl';
import { useCache, useValidationErrors } from '../hooks';
import { LegoSet } from '../types/api';
import { CheckControl, TextareaControl, TextControl } from '../components/forms';
import { DropdownControl } from '../components/forms/DropdownControl';
import { DateControl } from '../components/forms/DateControl';

interface Props {
   formik: Pick<
      FormikProps<Partial<LegoSet>>,
      | 'errors'
      | 'isValid'
      | 'touched'
      | 'values'
      | 'handleChange'
      | 'handleBlur'
      | 'isSubmitting'
      | 'setFieldValue'
      | 'submitCount'
   >;

   displayMode?: 'changeRequest' | 'admin';
}

export const LegoSetForm = ({ formik, displayMode = 'changeRequest' }: Props) => {
   const { categories } = useCache();

   const isResolvedViaBricklink = displayMode === 'changeRequest' && !!formik.values.bricklink_url;
   const blHelpText = (
      <Form.Text className="text-muted">
         <FormattedMessage
            id="form.lego_set.helpText.resolvedViaBricklink"
            defaultMessage="Diese Information wird automatisch via Bricklink abgerufen und kann daher nicht geändert werden."
         />
      </Form.Text>
   );

   const isResolvedViaLego = displayMode === 'changeRequest' && !!formik.values.lego_url;
   const legoHelpText = (
      <Form.Text className="text-muted">
         <FormattedMessage
            id="form.lego_set.helpText.resolvedViaLego"
            defaultMessage="Diese Information wird automatisch via Lego abgerufen und kann daher nicht geändert werden."
         />
      </Form.Text>
   );

   return (
      <Container className="px-0">
         <Row>
            <Col xl="6" xs="12">
               <fieldset>
                  <legend>Stammdaten</legend>
                  <TextControl formik={formik} model="lego_set" name="number" />
                  <TextControl
                     formik={formik}
                     model="lego_set"
                     name="name"
                     readOnly={isResolvedViaBricklink}
                     helpText={isResolvedViaBricklink && blHelpText}
                  />
                  <TextControl
                     formik={formik}
                     model="lego_set"
                     name="parts"
                     readOnly={isResolvedViaBricklink}
                     helpText={isResolvedViaBricklink && blHelpText}
                  />
                  <TextControl
                     formik={formik}
                     model="lego_set"
                     name="weight"
                     append="g"
                     readOnly={isResolvedViaBricklink}
                     helpText={isResolvedViaBricklink && blHelpText}
                  />
                  <TextControl
                     formik={formik}
                     model="lego_set"
                     name="size"
                     append="cm"
                     readOnly={isResolvedViaBricklink}
                     helpText={isResolvedViaBricklink && blHelpText}
                  />
                  <TextareaControl formik={formik} model="lego_set" name="remarks" rows={3} />
                  <DropdownControl
                     formik={formik}
                     model="lego_set"
                     name="category_id"
                     options={categories.map(c => ({ label: c.name, value: c.id }))}
                  />
                  <TextControl
                     formik={formik}
                     model="lego_set"
                     name="price"
                     inputType="number"
                     append="€"
                  />
                  <CheckControl formik={formik} model="lego_set" name="is_gwp" />
                  <CheckControl formik={formik} model="lego_set" name="retired" />
                  <DateControl formik={formik} model="lego_set" name="publish_date" />
                  <DateControl formik={formik} model="lego_set" name="eol_date" />
               </fieldset>
            </Col>
            <Col xl="6" xs="12">
               <fieldset>
                  <legend>Bricklink</legend>
                  <TextControl formik={formik} model="lego_set" name="bricklink_url" />
                  <DropdownControl
                     formik={formik}
                     model="lego_set"
                     name="bricklink_type"
                     options={[
                        {
                           label: 'Ausrüstung (Gear)',
                           value: 'gear',
                        },
                        {
                           label: 'Lego-Set',
                           value: 'sets',
                        },
                        {
                           label: 'Minifigur',
                           value: 'minifigure',
                        },
                     ]}
                  />
                  <TextControl formik={formik} model="lego_set" name="bricklink_id" />
               </fieldset>
               <fieldset>
                  <legend>Lego</legend>
                  <TextControl formik={formik} model="lego_set" name="lego_url" />
                  <TextControl
                     formik={formik}
                     model="lego_set"
                     name="availability"
                     readOnly={isResolvedViaLego}
                     helpText={isResolvedViaLego && legoHelpText}
                  />
               </fieldset>
               <fieldset>
                  <legend>Idealo</legend>
                  <TextControl formik={formik} model="lego_set" name="idealo_price_url" />
                  <TextControl formik={formik} model="lego_set" name="idealo_url" />
               </fieldset>
               <fieldset>
                  <legend>Brickset</legend>
                  <TextControl formik={formik} model="lego_set" name="brickset_id" />
               </fieldset>
            </Col>
         </Row>
      </Container>
   );
};

export const useValidationHandler = () => {
   const validationErrors = useValidationErrors();

   return useCallback(
      (values: Partial<LegoSet>) => {
         const errors: FormikErrors<LegoSet> = {};

         if (!values.number) errors.number = validationErrors.required;
         if (!values.name) errors.name = validationErrors.required;
         if (!values.category_id) errors.category_id = validationErrors.required;
         if (!values.publish_date) errors.publish_date = validationErrors.required;

         return errors;
      },
      [validationErrors]
   );
};
