import { Button, Card } from 'react-bootstrap';
import React from 'react';
import { mdiPencilOutline } from '@mdi/js';
import Icon from '@mdi/react';
import { Image } from '../molecules';
import { Colors } from '../atoms';
import { ImageUpload } from '../../types/api';
import ApiService from '../../services/ApiService';
import { useMemorizedIntl } from '../../hooks';

interface Props {
   image: ImageUpload;
   onEdit: (image: ImageUpload) => Promise<void>;
}

export const UploadedImageCard = ({ image, onEdit }: Props) => {
   const intl = useMemorizedIntl();

   return (
      <Card
         style={{ width: '8rem', height: '10rem', marginTop: '0.625rem', marginBottom: '0.625rem' }}
      >
         <a
            href={`${ApiService.apiEndpoint}/data/image_upload/${image.id}/image`}
            target="_blank"
            rel="noopener noreferrer"
         >
            <Card.Img
               as={Image}
               variant="top"
               style={{ height: '7rem' }}
               url={`${ApiService.apiEndpoint}/data/image_upload/${image.id}/image`}
            />
         </a>
         <Card.Body
            style={{
               padding: '0.5rem',
               backgroundColor: '#444',
               display: 'flex',
               justifyContent: 'center',
            }}
         >
            <Card.Title
               as="h6"
               style={{
                  marginTop: '0.3125rem',
                  marginBottom: '0.3125rem',
                  marginLeft: 0,
                  marginRight: 0,
               }}
            >
               {image.name}
            </Card.Title>
         </Card.Body>
         <Button
            variant="secondary"
            style={{ position: 'absolute', right: -10, top: -10 }}
            onClick={async () => onEdit(image)}
         >
            <Icon
               path={mdiPencilOutline}
               color={Colors.white}
               size={1}
               title={intl.formatMessage({
                  id: 'button.edit-image',
                  defaultMessage: 'Bild bearbeiten',
               })}
            />
         </Button>
      </Card>
   );
};
