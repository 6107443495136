import { Chart } from 'react-google-charts';
import React, { useMemo } from 'react';
import { ChartLoader } from '../atoms';
import { useChartData } from '../../hooks/useChartData';
import ApiService from '../../services/ApiService';
import { useAppSelector } from '../../hooks';

interface Props {
   /**
    * The url part to load the data.
    */
   url: string;
   /**
    * The number format to use for the values (e.g. #.##).
    */
   numberFormat?: string;
   /**
    * The unit to display behind the value (e.g. €).
    */
   unit?: string;
   /**
    * Additional options. These options override the already provided options and theme options.
    */
   options?: {};
}

export const BarChart = ({ url, numberFormat, unit }: Props) => {
   const stateLanguage = useAppSelector(s => s.app.language);
   // TODO: Übersetzungen, bzw. von außen übergeben
   const columnNames = useMemo(() => ['Datum', 'Einkauf', 'Verkauf'], []);
   const { isLoading, rows, columns, barChartOptions } = useChartData({
      url: `${ApiService.apiEndpoint}/${url}`,
      columnNames: columnNames,
      unit: unit,
      numberFormat: numberFormat,
   });

   return (
      <div style={{ display: 'flex' }}>
         {isLoading ? (
            <ChartLoader />
         ) : (
            <Chart
               width="100%"
               height="100%"
               chartType="ColumnChart"
               loader={<ChartLoader />}
               data={[columns, ...rows]}
               legendToggle
               options={barChartOptions}
               chartLanguage={stateLanguage}
            />
         )}
      </div>
   );
};
