import { createAsyncThunk, createSlice } from '@reduxjs/toolkit';
import { InventoryType } from '../types/api';
import { LegoSetWithStats } from '../types/api/LegoSetWithStats';
import ApiService from '../services/ApiService';
import { IApiResponse } from '../types/IApiResponse';

interface PagesState {
   inventoryByLegoSet: {
      entities?: LegoSetWithStats[];
      loading: boolean;
      loadedBy?: InventoryType | 'label' | 'category' | 'search';
   };
   legoSet: {
      loading: boolean;
      legoSet?: LegoSetWithStats;
   };
}

const initialState: PagesState = {
   inventoryByLegoSet: {
      entities: undefined,
      loading: false,
      loadedBy: undefined,
   },
   legoSet: {
      loading: false,
      legoSet: undefined,
   },
};

export const getInventoryByType = createAsyncThunk(
   'pages/getInventoryByType',
   async (inventoryType: InventoryType) => {
      try {
         return (
            await ApiService.http.get<IApiResponse<LegoSetWithStats>>(
               `inventory/byInventoryType/${inventoryType}`
            )
         ).data.data;
      } catch (error) {
         ApiService.handleError(error);
         return Promise.reject(error);
      }
   }
);
export const getInventoryByLabel = createAsyncThunk(
   'pages/getInventoryByLabel',
   async (labelId: number) => {
      try {
         return (
            await ApiService.http.get<IApiResponse<LegoSetWithStats>>(
               `inventory/byLabel/${labelId}`
            )
         ).data.data;
      } catch (error) {
         ApiService.handleError(error);
         return Promise.reject(error);
      }
   }
);
export const getInventoryByCategory = createAsyncThunk(
   'pages/getInventoryByCategory',
   async (categoryId: number) => {
      try {
         return (
            await ApiService.http.get<IApiResponse<LegoSetWithStats>>(
               `inventory/byCategory/${categoryId}`
            )
         ).data.data;
      } catch (error) {
         ApiService.handleError(error);
         return Promise.reject(error);
      }
   }
);

export const getInventoryBySearch = createAsyncThunk(
   'pages/getInventoryBySearch',
   async (searchText?: string) => {
      if (!searchText) return [];
      try {
         return (
            await ApiService.http.get<IApiResponse<LegoSetWithStats>>(
               `inventory/bySearch?q=${encodeURIComponent(searchText)}`
            )
         ).data.data;
      } catch (error) {
         ApiService.handleError(error);
         return Promise.reject(error);
      }
   }
);

export const loadLegoSet = createAsyncThunk('pages/loadLegoSet', async (legoSetId?: number) => {
   if (!legoSetId) return undefined;

   try {
      const legoSet = (
         await ApiService.http.get<IApiResponse<LegoSetWithStats>>(
            `inventory/byLegoSets/${legoSetId}`
         )
      ).data.data[0];
      if (!legoSet) return null;

      return legoSet;
   } catch (error) {
      ApiService.handleError(error);
      return Promise.reject(error);
   }
});

const pagesSlice = createSlice({
   name: 'pages',
   initialState,
   reducers: {},
   extraReducers: builder => {
      builder.addCase(getInventoryByType.pending, state => {
         state.inventoryByLegoSet.loading = true;
      });
      builder.addCase(getInventoryByType.fulfilled, (state, action) => {
         state.inventoryByLegoSet.entities = action.payload;
         state.inventoryByLegoSet.loading = false;
         state.inventoryByLegoSet.loadedBy = action.meta.arg;
      });
      builder.addCase(getInventoryByType.rejected, state => {
         state.inventoryByLegoSet.loading = false;
      });

      builder.addCase(getInventoryByLabel.pending, state => {
         state.inventoryByLegoSet.loading = true;
      });
      builder.addCase(getInventoryByLabel.fulfilled, (state, action) => {
         state.inventoryByLegoSet.entities = action.payload;
         state.inventoryByLegoSet.loading = false;
         state.inventoryByLegoSet.loadedBy = 'label';
      });
      builder.addCase(getInventoryByLabel.rejected, state => {
         state.inventoryByLegoSet.loading = false;
      });

      builder.addCase(getInventoryByCategory.pending, state => {
         state.inventoryByLegoSet.loading = true;
      });
      builder.addCase(getInventoryByCategory.fulfilled, (state, action) => {
         state.inventoryByLegoSet.entities = action.payload;
         state.inventoryByLegoSet.loading = false;
         state.inventoryByLegoSet.loadedBy = 'category';
      });
      builder.addCase(getInventoryByCategory.rejected, state => {
         state.inventoryByLegoSet.loading = false;
      });

      builder.addCase(getInventoryBySearch.pending, state => {
         state.inventoryByLegoSet.loading = true;
      });
      builder.addCase(getInventoryBySearch.fulfilled, (state, action) => {
         state.inventoryByLegoSet.entities = action.payload;
         state.inventoryByLegoSet.loading = false;
         state.inventoryByLegoSet.loadedBy = 'search';
      });
      builder.addCase(getInventoryBySearch.rejected, state => {
         state.inventoryByLegoSet.loading = false;
      });

      builder.addCase(loadLegoSet.pending, state => {
         state.legoSet.loading = true;
         state.legoSet.legoSet = undefined;
      });
      builder.addCase(loadLegoSet.fulfilled, (state, action) => {
         state.legoSet.loading = false;
         // undefined   --> Keine Änderung am State gewünscht
         // null        --> Lego-Set sollte geladen werden, existiert aber nicht, wird übernommen
         // <value>     --> geladenes Lego-Set übernehmen
         if (action.payload !== undefined) {
            state.legoSet.legoSet = action.payload ?? undefined;
         }
      });
      builder.addCase(loadLegoSet.rejected, state => {
         state.legoSet.loading = false;
      });
   },
});

export default pagesSlice.reducer;
