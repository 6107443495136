import React, { useCallback, useEffect, useState } from 'react';
import { Col, Container, Form, FormGroup, ListGroup, Row } from 'react-bootstrap';
import { Link } from 'react-router-dom';
import { mdiPlusThick } from '@mdi/js';
import Icon from '@mdi/react';
import { isMobile } from 'react-device-detect';
import { mdiChevronRight } from '@mdi/js/commonjs/mdi';
import { showBackButton } from '../../../redux/appSlice';
import { ColoredValue, Colors, ContentLoader } from '../../../components/atoms';
import { formatDate, pageLinks } from '../../../utils';
import { LinkButton } from '../../../components/molecules';
import { LegoSet } from '../../../types/api';
import { LegoSetImage } from '../../../components/molecules/LegoSetImage';
import { useAppDispatch, useCache } from '../../../hooks';
import { PageTitle } from '../../../components/organisms/PageTitle';
import { Pagination } from '../../../components/organisms/Pagination';
import ApiService from '../../../services/ApiService';
import { IApiResponse } from '../../../types/IApiResponse';
import { LegoSetModel } from '../../../models/LegoSetModel';

export const LegoSetsAdminPage = () => {
   const dispatch = useAppDispatch();
   const { categories } = useCache();
   const [filterKey, setFilterKey] = useState('');

   useEffect(() => {
      dispatch(showBackButton(true));
   }, [dispatch]);

   useEffect(() => {}, [filterKey]);

   const loadItems = useCallback(
      async (pageSize: number, page: number) =>
         (
            await ApiService.http.get<IApiResponse<LegoSet>>(
               `admin/legoSets?filterBy=${filterKey}&p=${page}&l=${pageSize}`
            )
         ).data,
      [filterKey]
   );

   return (
      <Pagination model={LegoSetModel} customLoadItems={loadItems} initialPageSize={50}>
         {({ items, maxItemCount }) => (
            <Container>
               <PageTitle title={`LegoSets (${maxItemCount})`} subtitle="Administration" />
               <Row className="mb-3">
                  <Col xs="6" sm="3">
                     <LinkButton to="/admin/legoSets/0" variant="success" block={isMobile}>
                        <span className="d-flex align-items-center justify-content-center">
                           <Icon
                              path={mdiPlusThick}
                              color={Colors.white}
                              size={1}
                              title="Neues Lego-Set hinzufügen"
                           />
                           &nbsp;Neues Lego-Set hinzufügen
                        </span>
                     </LinkButton>
                  </Col>
                  <Col xs="6" sm="3">
                     <FormGroup>
                        <Form.Control
                           as="select"
                           value={filterKey}
                           onChange={e => setFilterKey(e.target.value)}
                        >
                           <option value="">-- Sets filtern nach --</option>
                           <option value="bricklink_url">Fehlender Bricklink-URL</option>
                           <option value="brickset_id">Fehlender Brickset-ID</option>
                           <option value="idealo_price_url">Fehlender Idealo-Preis-URL</option>
                           <option value="idealo_url">Fehlender Idealo-URL</option>
                           <option value="lego_url">Fehlender Lego-URL</option>
                           <option value="parts_weight_size">Fehlende Teile/Gewicht/Größe</option>
                        </Form.Control>
                     </FormGroup>
                  </Col>
               </Row>
               <Row>
                  <Col>
                     <ListGroup>
                        {items === null ? (
                           <ListGroup.Item>
                              <ContentLoader style={{ height: '4rem' }} />
                           </ListGroup.Item>
                        ) : (
                           items.map(s => (
                              <ListGroup.Item
                                 key={s.id}
                                 as={Link}
                                 to={`/admin/legoSets/${s.id}`}
                                 className="d-flex flex-row gap-3"
                                 action
                              >
                                 <div
                                    className="flex-shrink-0"
                                    style={{
                                       width: '5.5rem',
                                       height: '5.5rem',
                                    }}
                                 >
                                    <LegoSetImage legoSet={s} thumbnail />
                                 </div>
                                 <div
                                    className="d-flex flex-column flex-fill align-items-start justify-content-between"
                                    style={{ width: 0 }}
                                 >
                                    <span className="fs-4 overflow-ellipsis w-100">
                                       <Link
                                          to={pageLinks.legoSetDetail(s)}
                                          style={{ maxWidth: '100%' }}
                                          onClick={e => e.stopPropagation()}
                                       >
                                          {`${s.number}: ${s.name}`}
                                       </Link>
                                    </span>
                                    <Link
                                       to={pageLinks.legoSetsByCategory(
                                          categories.find(c => c.id === s.category_id)
                                       )}
                                       onClick={e => e.stopPropagation()}
                                    >
                                       {s.category_name}
                                    </Link>
                                    <span className="d-flex flex-row gap-2 text-muted">
                                       <ColoredValue type="currency" value={s.price} />
                                       <span>•</span>
                                       <span>{formatDate(s.publish_date, 'L')}</span>
                                       {s.retired && (
                                          <>
                                             <span>•</span>
                                             <span>EOL</span>
                                          </>
                                       )}
                                    </span>
                                 </div>
                                 <div className="d-flex align-items-center">
                                    <Icon path={mdiChevronRight} color={Colors.link} size={1} />
                                 </div>
                              </ListGroup.Item>
                           ))
                        )}
                     </ListGroup>
                  </Col>
               </Row>
            </Container>
         )}
      </Pagination>
   );
};
