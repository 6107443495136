import { FormattedMessage } from 'react-intl';
import React, { useEffect } from 'react';
import { Button, Container, Image, ListGroup } from 'react-bootstrap';
import { Link } from 'react-router-dom';
import Icon from '@mdi/react';
import { mdiCrown, mdiShieldStarOutline } from '@mdi/js';
import { setActiveTab, showBackButton } from '../../redux/appSlice';
import { Colors, VersionNumber } from '../../components/atoms';
import { LanguageDropdown } from '../../components/organisms/LanguageDropdown';
import { useAppDispatch, useMemorizedIntl, useSession } from '../../hooks';
import { ROLE_ADMIN } from '../../types/api';
import { ShowIfRole } from '../../components/molecules/ShowIfRole';
import { PageTitle } from '../../components/organisms/PageTitle';

export const MoreMobilePage = () => {
   const dispatch = useAppDispatch();
   const intl = useMemorizedIntl();
   const { sessionUser, signOut } = useSession();

   useEffect(() => {
      dispatch(showBackButton(false));
      dispatch(setActiveTab('More'));
   }, [dispatch]);

   return (
      <Container>
         <PageTitle
            title={intl.formatMessage({
               id: 'more.title',
               defaultMessage: 'Mehr',
            })}
            hidden
         />
         <div className="pt-2 pb-3 d-flex flex-column align-items-center">
            <div style={{ position: 'relative' }}>
               <Image
                  src={sessionUser?.picture_url}
                  style={{ width: '6rem', height: '6rem' }}
                  roundedCircle
               />
               <ShowIfRole roles={[ROLE_ADMIN]}>
                  <span
                     style={{
                        position: 'absolute',
                        display: 'block',
                        top: -8,
                        right: -8,
                        backgroundColor: Colors.background,
                        borderRadius: '1.375rem',
                        padding: '0.25rem',
                     }}
                  >
                     <Icon
                        style={{
                           backgroundColor: Colors.secondary,
                           borderRadius: '1.125rem',
                           padding: '0.375rem',
                        }}
                        path={mdiCrown}
                        color="#FFD700"
                        size={1.5}
                     />
                  </span>
               </ShowIfRole>
            </div>
            <span>{sessionUser?.name}</span>
         </div>
         <ShowIfRole roles={[ROLE_ADMIN]}>
            <ListGroup className="pb-3">
               <ListGroup.Item as={Link} to="/admin" className="text-center">
                  <span className="fst-italic d-flex align-items-center justify-content-center">
                     <Icon
                        path={mdiShieldStarOutline}
                        className="me-1"
                        color="#FFD700"
                        size={0.75}
                     />
                     Administration
                  </span>
               </ListGroup.Item>
            </ListGroup>
         </ShowIfRole>
         <ListGroup>
            <ListGroup.Item as={Link} to="/user/profile" className="text-center">
               <FormattedMessage id="navbar.profile" defaultMessage="Profil" />
            </ListGroup.Item>
            <ListGroup.Item className="text-center">
               <LanguageDropdown />
            </ListGroup.Item>
         </ListGroup>
         <ListGroup className="pt-3">
            <ListGroup.Item as={Link} to="/imprint" className="text-center">
               <FormattedMessage id="footer.imprint" defaultMessage="Impressum & Datenschutz" />
            </ListGroup.Item>
            <ListGroup.Item
               as="a"
               className="text-center"
               href="https://forum.brickfolio.de"
               target="_blank"
               rel="noopener noreferrer"
            >
               <FormattedMessage id="footer.forum" defaultMessage="Forum" />
            </ListGroup.Item>
            <ListGroup.Item as={Link} to="/faq" className="text-center">
               <FormattedMessage id="footer.faq" defaultMessage="FAQ" />
            </ListGroup.Item>
         </ListGroup>
         <ListGroup className="pt-3">
            <ListGroup.Item as="a" href="mailto:feedback@brickfolio.de" className="text-center">
               <FormattedMessage
                  id="footer.report-improvement"
                  defaultMessage="Fehler/Feature/Feedback melden"
               />
            </ListGroup.Item>
            <ListGroup.Item
               as="a"
               href="mailto:info@brickfolio.de?subject=Hey%2C%20Ich%20m%C3%B6chte%20deine%20coole%20Seite%20gerne%20unterst%C3%BCtzen&body=Hey%2C%0A%0Aich%20finde%20deine%20Seite%20echt%20cool%20und%20m%C3%B6chte%20diese%20unterst%C3%BCtzen.%20Leider%20habe%20ich%20kein%20Patreon%2C%20kannst%20du%20mir%20daher%20deine%20PayPal-Adresse%2FIBAN%20nennen%3F%0A%0ALG"
               target="_blank"
               rel="noopener noreferrer"
               className="text-center"
            >
               <FormattedMessage
                  id="footer.donate.email"
                  defaultMessage="Kontakt für Spenden/Unterstützung"
               />
            </ListGroup.Item>
         </ListGroup>
         <div className="d-flex justify-content-center mt-4">
            <Button variant="link" style={{ color: Colors.red }} onClick={() => signOut()}>
               <FormattedMessage id="navbar.logout" defaultMessage="Abmelden" />
            </Button>
         </div>
         <div
            className="d-flex flex-row justify-content-between mt-4 text-center fst-italic"
            style={{ color: Colors.secondary, fontSize: 12 }}
         >
            <VersionNumber />
            <Link to="/changelog">
               <FormattedMessage id="footer.changelog" defaultMessage="Changelog" />
            </Link>
         </div>
      </Container>
   );
};
