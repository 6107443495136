import React from 'react';
import { Badge, Navbar } from 'react-bootstrap';
import Icon from '@mdi/react';
import {
   mdiBookshelf,
   mdiChartLine,
   mdiDatabaseSearchOutline,
   mdiMenu,
   mdiNewspaperVariantOutline,
} from '@mdi/js';
import { FormattedMessage } from 'react-intl';
import { Link } from 'react-router-dom';
import { LinkButton } from '../components/molecules';
import { Colors } from '../components/atoms';
import { useAppSelector, useSession } from '../hooks';

export const AppBottomNav = () => {
   const { sessionUser } = useSession();
   const badgeNews = useAppSelector(s => s.app.badges.news);

   return (
      <Navbar id="mobile-footer" fixed="bottom">
         {!sessionUser ? (
            <div className="d-flex flex-column align-items-center flex-fill">
               <Link to="/imprint">
                  <FormattedMessage id="footer.imprint" defaultMessage="Impressum & Datenschutz" />
               </Link>
               <Link to="/blog">
                  <FormattedMessage id="footer.news-and-blog" defaultMessage="News & Blog" />
               </Link>
            </div>
         ) : (
            <div id="bottom-tab-bar">
               <NavIconWithBadge tabKey="Statistic" to="/stats" icon={mdiChartLine} />
               <NavIconWithBadge tabKey="Inventory" to="/inventory/bought" icon={mdiBookshelf} />
               <NavIconWithBadge
                  tabKey="InventorySearch"
                  to="/inventory/search"
                  icon={mdiDatabaseSearchOutline}
               />
               <NavIconWithBadge
                  tabKey="Blog"
                  to="/blog"
                  icon={mdiNewspaperVariantOutline}
                  badge={badgeNews}
                  hideBadgeValue
               />
               <NavIconWithBadge tabKey="More" to="/more" icon={mdiMenu} />
            </div>
         )}
      </Navbar>
   );
};

type NavIconWithBadgeProps = {
   tabKey: string;
   to: string;
   icon: string;
   badge?: number;
   hideBadgeValue?: boolean;
};

const NavIconWithBadge = ({
   tabKey,
   to,
   icon,
   badge = 0,
   hideBadgeValue = false,
}: NavIconWithBadgeProps) => {
   const { activeTab } = useAppSelector(s => s.app);

   return (
      <LinkButton to={to} className={`position-relative ${tabKey === activeTab ? 'active' : ''}`}>
         <span className="nav-icon">
            <Icon path={icon} color={Colors.white} size={1} />
         </span>
         {!hideBadgeValue && badge > 0 && (
            <Badge
               pill
               bg="warning"
               style={{ position: 'absolute', top: '1.25rem', right: '1.25rem' }}
            >
               {badge}
            </Badge>
         )}
         {hideBadgeValue && badge > 0 && (
            <Badge
               pill
               bg="warning"
               style={{
                  position: 'absolute',
                  top: '1.25rem',
                  right: '1.25rem',
                  height: '1rem',
                  width: '1rem',
               }}
            >
               &nbsp;
            </Badge>
         )}
      </LinkButton>
   );
};
