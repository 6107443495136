import { Alert, Container } from 'react-bootstrap';
import React from 'react';
import { PageTitle } from '../../components/organisms/PageTitle';
import './index.scss';

export const ChangelogPage = () => (
   <Container className="p-3 changelog">
      <PageTitle title="Changelog" />
      <Alert variant="secondary">
         <div>All notable changes to this project will be documented in this file.</div>
         <br />
         <div>
            The format is based on{' '}
            <a
               href="https://keepachangelog.com/en/1.0.0/"
               target="_blank"
               rel="noopener noreferrer"
            >
               Keep a Changelog
            </a>
            , and this project adheres to{' '}
            <a href="https://semver.org/spec/v2.0.0.html" target="_blank" rel="noopener noreferrer">
               Semantic Versioning
            </a>
            .
         </div>
      </Alert>
      <h2>3.0.0 - XXXX-XX-XX</h2>
      <Alert variant="info">
         <Alert.Heading>Major changes!</Alert.Heading>
         Complete redesign and reimplementation of backend API and partially redesign and
         reimplementation of frontend due to integration of new backend and updated frameworks.
      </Alert>
      <h4>Fixes</h4>
      <ul>
         <li>Always sort inventory list by id to keep the order the same after changing an item</li>
         <li>
            Hides the add reaction button for a blog post for not logged-in users (BRICKWEB-120)
         </li>
         <li>
            Fixes a bug where the notification for the blog does not disappear when visiting the
            blog (BRICKWEB-119)
         </li>
      </ul>
      <h4>Added</h4>
      <ul>
         <li>Shows number of uploaded images for an inventory (BRICKWEB-88)</li>
         <li>Added option to create multiple inventory entries in a row (BRICKWEB-99)</li>
         <li>
            Links to associated sites with the specific LEGO set (Lego, Bricklink, Brickset, Idealo)
         </li>
      </ul>
      <h4>Changed</h4>
      <ul>
         <li>Price chart now shows a price range for its current market value</li>
         <li>Redesign Lego-Set card on desktop view (BRICKWEB-124)</li>
         <li>The import of a new LEGO set is done directly inside the dialog (BRICKWEB-57)</li>
         <li>Redesign of the label management for a LEGO set</li>
         <li>Changed of url so that they expose more information about the content</li>
      </ul>

      <h2>2.3.0 - 2021-03-13</h2>
      <h4>Fixed</h4>
      <ul>
         <li>
            Shows loading animation instead of 404 error when opening a deeplink and the google
            login is executing (BRICKWEB-108)
         </li>
      </ul>
      <h4>Added</h4>
      <ul>
         <li>Shows an error message when the google login failed (BRICKWEB-108)</li>
         <li>Direct link to blog for mobile view</li>
         <li>Button for notifications on desktop view</li>
         <li>Shows an indicator/notification when a new blog post is published</li>
      </ul>
      <h4>Changed</h4>
      <ul>
         <li>
            On mobile view, moves the links to wanted/bought/sold inventory from bottom nav bar to a
            top tabs bar
         </li>
      </ul>

      <h2>2.2.1 - 2021-05-19</h2>
      <h4>Fixed</h4>
      <ul>
         <li>Custom filter can now be deleted (BRICKWEB-137)</li>
         <li>
            The fields for sorting of a custom filter can no be removed correctly (BRICKWEB-136)
         </li>
      </ul>

      <h2>2.2.0 - 2021-05-09</h2>
      <h4>Added</h4>
      <ul>
         <li>New eBay payment and provision model for price calculator (BRICKWEB-133)</li>
      </ul>

      <h2>2.1.0 - 2021-02-27</h2>
      <h4>Added</h4>
      <ul>
         <li>Custom filter for inventory based on various attributes (BRICKWEB-109)</li>
         <li>Blog for news and updates regarding brickfolio.de (BRICKWEB-52)</li>
         <li>FAQ (BRICKWEB-52)</li>
         <li>Nickname for users (BRICKWEB-52)</li>
      </ul>
      <h4>Changed</h4>
      <ul>
         <li>
            Registration process now required the new user to set a nickname before using the page
            (BRICKWEB-52)
         </li>
         <li>Color of white text and icons are slightly reduced for better readability</li>
      </ul>

      <h2>2.0.0 - 2021-01-10</h2>
      <h4>Added</h4>
      <ul>
         <li>
            Optimized layout to simulate an app-like user experience on mobile devices (BRICKWEB-34)
         </li>
         <li>Show a dropdown list with results while typing in search bar (BRICKWEB-13)</li>
         <li>Show errors as a toast message (BRICKWEB-38)</li>
         <li>SEO and robots.txt (BRICKWEB-39)</li>
         <li>
            Support for multiple languages aka I18n and translation for german and english
            (BRICKWEB-40)
         </li>
         <li>Sort option for purchase date / purchase date (BRICKWEB-62)</li>
         <li>Dropdowns are now scrollable to prevent long lists (BRICKWEB-69)</li>
         <li>Display LEGO set picture in large when being clicked (BRICKWEB-78)</li>
         <li>Logging of market value import to resolve import issues (BRICKWEB-93)</li>
      </ul>
      <h4>Changed</h4>
      <ul>
         <li>Complete rewrite of website from AngularJS with ReactJS (BRICKWEB-17)</li>
      </ul>
      <h4>Fixed</h4>
      <ul>
         <li>Fixed character escaping in set importer (BRICKWEB-91)</li>
      </ul>

      <h2>1.0.0 - 2018-04-05</h2>
      <ul>
         <li>Creation of Brickfolio.de</li>
      </ul>
   </Container>
);
