import React, { useEffect } from 'react';
import { useParams } from 'react-router-dom';
import { FormattedMessage } from 'react-intl';
import { isMobile } from 'react-device-detect';
import { Container } from 'react-bootstrap';
import { LegoSetsGridList } from '../../components/organisms/LegoSetsGridList';
import { ContentLoader } from '../../components/atoms';
import { useAppDispatch, useAppSelector, useMemorizedIntl } from '../../hooks';
import { getInventoryBySearch } from '../../redux/pagesSlice';
import { PageTitle } from '../../components/organisms/PageTitle';

export const SearchPage = () => {
   const intl = useMemorizedIntl();
   const dispatch = useAppDispatch();
   const { loading, entities } = useAppSelector(s => s.pages.inventoryByLegoSet);
   const { query } = useParams<{ query?: string }>();

   useEffect(() => {
      (async () => {
         dispatch(getInventoryBySearch(query));
      })();
   }, [dispatch, query]);

   return (
      <Container>
         <PageTitle
            title={intl.formatMessage(
               {
                  id: 'search-result.title',
                  defaultMessage: 'Suche nach "{query}"',
               },
               {
                  query: query,
               }
            )}
            appTitle={intl.formatMessage(
               {
                  id: 'search-result.app-title',
                  defaultMessage: 'Suche nach <em>{query}</em>',
               },
               {
                  query: query,
               }
            )}
         >
            <FormattedMessage
               id="search-result.headline"
               defaultMessage="Suche nach: <em>{query}</em>"
               values={{
                  query: query,
               }}
            />
         </PageTitle>
         {loading && <ContentLoader />}
         {!loading && entities?.length === 0 && (
            <div
               className={`d-flex mt-4 ${
                  isMobile ? 'justify-content-center' : 'justify-content-start'
               }`}
            >
               <em>
                  <FormattedMessage
                     id="search-result.no-results"
                     defaultMessage="Es wurden keine LEGO-Sets gefunden."
                  />
               </em>
            </div>
         )}
         {!loading && (entities?.length ?? 0) > 0 && <LegoSetsGridList hideOrdering />}
      </Container>
   );
};
