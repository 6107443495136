import React, { useEffect, useMemo, useState } from 'react';
import { isMobile } from 'react-device-detect';
import { Accordion, Alert, Card, Modal, Table } from 'react-bootstrap';
import { FormattedMessage } from 'react-intl';
import {
   mdiInformationOutline,
   mdiPackageVariantClosed,
   mdiShieldCheckOutline,
   mdiShieldOffOutline,
   mdiWeightGram,
} from '@mdi/js';
import Icon from '@mdi/react';
import { ColoredValue, Colors } from '../../../components/atoms';
import { ButtonPanel, ResponsiveButton } from '../../../components/molecules';
import { InfoTooltip } from '../../../components/molecules/InfoTooltip';
import { useMemorizedIntl } from '../../../hooks';

interface Props {
   size?: string | null;
   weight?: number | null;
   avgMarketPrice?: number;
}
export const ShippingCostsDialogButton = (props: Props) => {
   const [show, setShow] = useState(false);
   const [carton, setCarton] = useState<Carton | null>(null);

   useEffect(() => {
      // L x B x H
      const sortedParts = props.size
         ?.split('x')
         .map(p => Number(p.trim()))
         .sort((a, b) => b - a);

      if (sortedParts?.length === 3 && props.weight) {
         // Um ein wenig "Spiel" beim Verpacken zu haben und da ja der Karton ebenfalls etwas Dicke und Gewicht mitbringt,
         //    wird an jeder Kante 10% dazugerechnet und beim Gewicht ebenfalls 10% dazu.
         setCarton({
            length: Math.ceil(sortedParts[0] * 1.1),
            width: Math.ceil(sortedParts[1] * 1.1),
            height: Math.ceil(sortedParts[2] * 1.1),
            weight: Math.ceil(props.weight * 1.1),
            avgMarketPrice: props.avgMarketPrice ?? 0,
         });
      } else {
         setCarton(null);
      }
   }, [props.size, props.weight, props.avgMarketPrice]);

   return (
      <>
         <ResponsiveButton
            onClick={() => setShow(true)}
            variant="primary"
            className="px-1 flex-fill d-flex flex-row align-items-center justify-content-center gap-2"
            disabled={!props.size && !props.weight}
         >
            <Icon path={mdiPackageVariantClosed} color={Colors.white} size={1.25} />
            <small className="text-start">
               <FormattedMessage
                  id="shipping-costs.button.show-hide.long"
                  defaultMessage="Versand­kosten"
               />
            </small>
         </ResponsiveButton>
         <Modal show={show} onHide={() => setShow(false)} centered={isMobile} size="lg">
            <Modal.Header closeButton>
               <Modal.Title>
                  <FormattedMessage
                     id="dialog.shipping-costs.title"
                     defaultMessage="Versandkosten"
                  />
               </Modal.Title>
            </Modal.Header>

            <Modal.Body>
               {(carton?.avgMarketPrice ?? 0) > 500 && (
                  <Alert
                     variant="warning"
                     className="small"
                     style={{ marginLeft: 1, marginRight: 1, marginBottom: '0.625rem' }}
                  >
                     <FormattedMessage
                        id="dialog.shipping-costs.insurance-alert"
                        defaultMessage="Der durchschnittliche Marktwert liegt über 500€. Bitte berücksichtige die Höhe der Paketversicherung."
                     />
                  </Alert>
               )}
               <Accordion className="mb-2" flush>
                  <DhlRecommendationAccordion carton={carton} withInsurance />
                  <DhlRecommendationAccordion carton={carton} />
                  <HermesRecommendationAccordion carton={carton} type="shop" />
                  <HermesRecommendationAccordion carton={carton} type="door" />
               </Accordion>
               <div className="d-flex align-items-center">
                  <Icon
                     path={mdiShieldCheckOutline}
                     color={Colors.success}
                     size={0.75}
                     className="me-1"
                  />
                  mit Paketversicherung
               </div>
               <div className="d-flex align-items-center">
                  <Icon
                     path={mdiShieldOffOutline}
                     color={Colors.danger}
                     size={0.75}
                     className="me-1"
                  />
                  ohne Paketversicherung
               </div>
            </Modal.Body>

            <Modal.Footer>
               <ButtonPanel>
                  <ResponsiveButton variant="secondary" onClick={() => setShow(false)}>
                     <FormattedMessage id="button.close" defaultMessage="Schließen" />
                  </ResponsiveButton>
               </ButtonPanel>
            </Modal.Footer>
         </Modal>
      </>
   );
};

interface Carton {
   length: number;
   width: number;
   height: number;
   weight: number;
   avgMarketPrice: number;
}

// region DHL

interface DhlShippingOption {
   name: string;
   price: number;
   insurance: number;
   length: number;
   width: number;
   height: number;
   weight: number;
}

const DhlRecommendationAccordion = ({
   carton,
   withInsurance = false,
}: {
   carton: Carton | null;
   withInsurance?: boolean;
}) => {
   const intl = useMemorizedIntl();

   const dhlPackages: DhlShippingOption[] = useMemo(
      () => [
         {
            name: intl.formatMessage({
               id: 'dialog.shipping-costs.dhl.parcel-s',
               defaultMessage: 'Päckchen S',
            }),
            price: 3.99,
            insurance: 0,
            length: 35,
            width: 25,
            height: 10,
            weight: 2000,
         },
         {
            name: intl.formatMessage({
               id: 'dialog.shipping-costs.dhl.parcel-m',
               defaultMessage: 'Päckchen M',
            }),
            price: 4.79,
            insurance: 0,
            length: 60,
            width: 30,
            height: 15,
            weight: 2000,
         },
         {
            name: intl.formatMessage({
               id: 'dialog.shipping-costs.dhl.package-2kg',
               defaultMessage: '2kg Paket',
            }),
            price: 5.49,
            insurance: 500,
            length: 60,
            width: 30,
            height: 15,
            weight: 2000,
         },
         {
            name: intl.formatMessage({
               id: 'dialog.shipping-costs.dhl.package-5kg',
               defaultMessage: '5kg Paket',
            }),
            price: 6.99,
            insurance: 500,
            length: 120,
            width: 60,
            height: 60,
            weight: 5000,
         },
         {
            name: intl.formatMessage({
               id: 'dialog.shipping-costs.dhl.package-10kg',
               defaultMessage: '10kg Paket',
            }),
            price: 9.49,
            insurance: 500,
            length: 120,
            width: 60,
            height: 60,
            weight: 10000,
         },
         {
            name: intl.formatMessage({
               id: 'dialog.shipping-costs.dhl.package-315kg',
               defaultMessage: '31,5kg Paket',
            }),
            price: 16.49,
            insurance: 500,
            length: 120,
            width: 60,
            height: 60,
            weight: 31500,
         },
      ],
      [intl]
   );

   const bestPackage = useMemo((): DhlShippingOption | null => {
      if (!carton) return null;

      let bestChoice: DhlShippingOption | null = null;
      dhlPackages.forEach(p => {
         if (withInsurance && p.insurance === 0) return;
         if (!withInsurance && p.insurance > 0) return;

         // Maße und Gewicht müssen stimmen
         if (
            bestChoice === null &&
            carton.length < p.length &&
            carton.width < p.width &&
            carton.height < p.height &&
            carton.weight < p.weight
         ) {
            bestChoice = p;
         }
      });

      return bestChoice;
   }, [carton, dhlPackages, withInsurance]);

   const optionsTable = useMemo(() => {
      let firstMatchingOption: DhlShippingOption | null = null;

      return dhlPackages.map(p => {
         if (!carton) return null;

         if (withInsurance && p.insurance === 0) return null;
         if (!withInsurance && p.insurance > 0) return null;

         if (
            firstMatchingOption === null &&
            carton.length < p.length &&
            carton.width < p.width &&
            carton.height < p.height &&
            carton.weight < p.weight
         ) {
            firstMatchingOption = p;
         }

         return (
            <tr key={p.name} className={firstMatchingOption === p ? 'bg-success' : ''}>
               <th>{p.name}</th>
               <td
                  className={`text-center ${
                     !firstMatchingOption && carton.length > p.length ? 'bg-danger' : ''
                  }`}
               >
                  {p.length}
               </td>
               <td
                  className={`text-center ${
                     !firstMatchingOption && carton.width > p.width ? 'bg-danger' : ''
                  }`}
               >
                  {p.width}
               </td>
               <td
                  className={`text-center ${
                     !firstMatchingOption && carton.height > p.height ? 'bg-danger' : ''
                  }`}
               >
                  {p.height}
               </td>
               <td
                  className={`text-center ${
                     !firstMatchingOption && carton.weight > p.weight ? 'bg-danger' : ''
                  }`}
               >
                  {Math.round(p.weight / 100) / 10} kg
               </td>
            </tr>
         );
      });
   }, [carton, dhlPackages, withInsurance]);

   return (
      <Accordion.Item as={Card} eventKey={`dhl${withInsurance ? '-insurance' : ''}`}>
         <Accordion.Button
            as={Card.Header}
            className="d-flex justify-content-between align-items-center ps-2 pe-2 accordion-hide-indicator"
         >
            <span className="d-flex align-items-center">
               {withInsurance ? (
                  <Icon
                     path={mdiShieldCheckOutline}
                     color={Colors.success}
                     size={0.75}
                     className="me-1"
                  />
               ) : (
                  <Icon
                     path={mdiShieldOffOutline}
                     color={Colors.danger}
                     size={0.75}
                     className="me-1"
                  />
               )}
               <span>DHL:&nbsp;</span>
               {bestPackage !== null ? (
                  <span>
                     <ColoredValue type="currency" value={bestPackage.price} className="fw-bold" />
                     <span className="text-muted small ps-2">({bestPackage.name})</span>
                  </span>
               ) : (
                  <span className="fst-italic">
                     <FormattedMessage
                        id="dialog.shipping-costs.options.none"
                        defaultMessage="Keines"
                     />
                  </span>
               )}
            </span>
            <span className="small" style={{ color: Colors.link }}>
               <FormattedMessage id="dialog.shipping-costs.details" defaultMessage="Details" />
            </span>
         </Accordion.Button>
         <Accordion.Body
            as={Card.Body}
            className="p-2"
            eventKey={`dhl${withInsurance ? '-insurance' : ''}`}
         >
            <div>
               <Table size="sm" striped className="mb-0">
                  <thead>
                     <tr>
                        <th aria-label="entry" />
                        <th className="text-center">
                           <FormattedMessage
                              id="dialog.shipping-costs.table-header.length"
                              defaultMessage="L"
                           />
                        </th>
                        <th className="text-center">
                           <FormattedMessage
                              id="dialog.shipping-costs.table-header.width"
                              defaultMessage="B"
                           />
                        </th>
                        <th className="text-center">
                           <FormattedMessage
                              id="dialog.shipping-costs.table-header.height"
                              defaultMessage="H"
                           />
                        </th>
                        <th className="text-center">
                           <Icon path={mdiWeightGram} color={Colors.white} size={0.75} />
                        </th>
                     </tr>
                  </thead>
                  <tbody>
                     <tr>
                        <th className="d-flex align-items-center">
                           <span>
                              <FormattedMessage
                                 id="dialog.shipping-costs.table.option.lego"
                                 defaultMessage="LEGO"
                              />
                           </span>
                           <InfoTooltip
                              icon={mdiInformationOutline}
                              text={intl.formatMessage({
                                 id: 'dialog.shipping-costs.info',
                                 defaultMessage:
                                    'Für die Berechnung werden auf Größe und Gewicht des Kartons jeweils 10% aufgeschlagen. Dies soll für Spielraum beim Verpacken sorgen.',
                              })}
                           />
                        </th>
                        <td className="text-center">{carton?.length}</td>
                        <td className="text-center">{carton?.width}</td>
                        <td className="text-center">{carton?.height}</td>
                        <td className="text-center">
                           {(Math.round(carton?.weight ?? 0) / 100 / 10).toFixed(1)} kg
                        </td>
                     </tr>
                     {optionsTable}
                  </tbody>
               </Table>
            </div>
         </Accordion.Body>
      </Accordion.Item>
   );
};
// endregion

// region Hermes

interface HermesShippingOption {
   name: string;
   price: {
      shop: number;
      door: number;
   };
   insurance: number;
   size: number;
   weight: number;
}

const HermesRecommendationAccordion = ({
   carton,
   type,
}: {
   carton: Carton | null;
   type: 'shop' | 'door';
}) => {
   const intl = useMemorizedIntl();
   const [size, setSize] = useState(0);

   const hermesPackages: HermesShippingOption[] = useMemo(
      () => [
         {
            name: intl.formatMessage({
               id: 'dialog.shipping-costs.hermes.parcel',
               defaultMessage: 'Päckchen',
            }),
            price: {
               shop: 3.6,
               door: 4.2,
            },
            insurance: 50,
            size: 37,
            weight: 25000,
         },
         {
            name: intl.formatMessage({
               id: 'dialog.shipping-costs.hermes.s-package',
               defaultMessage: 'S-Paket',
            }),
            price: {
               shop: 4.3,
               door: 4.8,
            },
            insurance: 500,
            size: 50,
            weight: 25000,
         },
         {
            name: intl.formatMessage({
               id: 'dialog.shipping-costs.hermes.m-package',
               defaultMessage: 'M-Paket',
            }),
            price: {
               shop: 5.25,
               door: 5.8,
            },
            insurance: 500,
            size: 80,
            weight: 25000,
         },
         {
            name: intl.formatMessage({
               id: 'dialog.shipping-costs.hermes.l-package',
               defaultMessage: 'L-Paket',
            }),
            price: {
               shop: 10.15,
               door: 10.65,
            },
            insurance: 500,
            size: 120,
            weight: 25000,
         },
      ],
      [intl]
   );

   useEffect(() => {
      if (!carton) {
         setSize(0);
      } else {
         const maxLength = Math.max(carton.height, carton.width, carton.length);
         const minLength = Math.min(carton.height, carton.width, carton.length);
         setSize(maxLength + minLength);
      }
   }, [carton]);

   const bestPackage = useMemo((): HermesShippingOption | null => {
      if (!carton || size === 0) return null;

      let bestChoice: HermesShippingOption | null = null;
      hermesPackages.forEach(p => {
         // Maße und Gewicht müssen stimmen
         if (bestChoice === null && size < p.size && carton.weight < p.weight) {
            bestChoice = p;
         }
      });

      return bestChoice;
   }, [carton, hermesPackages, size]);

   const optionsTable = useMemo(() => {
      let firstMatchingOption: HermesShippingOption | null = null;

      return hermesPackages.map(p => {
         if (!carton) return null;

         if (firstMatchingOption === null && size < p.size && carton.weight < p.weight) {
            firstMatchingOption = p;
         }

         return (
            <tr key={p.name} className={firstMatchingOption === p ? 'bg-success' : ''}>
               <th>{p.name}</th>
               <td
                  className={`text-center ${
                     !firstMatchingOption && size > p.size ? 'bg-danger' : ''
                  }`}
               >
                  {p.size}
               </td>
               <td
                  className={`text-center ${
                     carton.avgMarketPrice > p.insurance ? 'bg-warning' : ''
                  }`}
               >
                  {p.insurance} €
               </td>
               <td
                  className={`text-center ${
                     !firstMatchingOption && carton.weight > p.weight ? 'bg-danger' : ''
                  }`}
               >
                  {Math.round(p.weight / 100) / 10} kg
               </td>
            </tr>
         );
      });
   }, [carton, hermesPackages, size]);

   return (
      <Accordion.Item as={Card} eventKey={`hermes-${type}`}>
         <Accordion.Button
            as={Card.Header}
            className="d-flex justify-content-between align-items-center ps-2 pe-2 accordion-hide-indicator"
         >
            <span className="d-flex align-items-center">
               <Icon
                  path={mdiShieldCheckOutline}
                  color={Colors.success}
                  size={0.75}
                  className="me-1"
               />
               {type === 'shop' ? (
                  <span>
                     <FormattedMessage
                        id="dialog.shipping-costs.options.hermes-shop"
                        defaultMessage="Hermes (Shop)"
                     />
                     :&nbsp;
                  </span>
               ) : (
                  <span>
                     <FormattedMessage
                        id="dialog.shipping-costs.options.hermes-door"
                        defaultMessage="Hermes (Haustür)"
                     />
                     :&nbsp;
                  </span>
               )}
               {bestPackage !== null ? (
                  <span>
                     <ColoredValue
                        type="currency"
                        value={type === 'shop' ? bestPackage.price.shop : bestPackage.price.door}
                        className="fw-bold"
                     />
                     <span className="text-muted small ps-2">({bestPackage.name})</span>
                  </span>
               ) : (
                  <span className="fst-italic">
                     <FormattedMessage
                        id="dialog.shipping-costs.options.none"
                        defaultMessage="Keines"
                     />
                  </span>
               )}
            </span>
            <span className="small" style={{ color: Colors.link }}>
               <FormattedMessage id="dialog.shipping-costs.details" defaultMessage="Details" />
            </span>
         </Accordion.Button>
         <Accordion.Body as={Card.Body} className="p-2" eventKey={`hermes-${type}`}>
            <div>
               <div className="text-muted small pb-2">
                  <FormattedMessage
                     id="dialog.shipping-costs.hermes-size-info"
                     defaultMessage="Größe = längste + kürzeste Kante"
                  />
               </div>
               <Table size="sm" striped className="mb-0">
                  <thead>
                     <tr>
                        <th aria-label="entry" />
                        <th className="text-center">
                           <FormattedMessage
                              id="dialog.shipping-costs.table-header.size"
                              defaultMessage="Größe"
                           />
                        </th>
                        <th className="text-center">
                           <Icon path={mdiShieldCheckOutline} color={Colors.white} size={0.75} />
                        </th>
                        <th className="text-center">
                           <Icon path={mdiWeightGram} color={Colors.white} size={0.75} />
                        </th>
                     </tr>
                  </thead>
                  <tbody>
                     <tr>
                        <th className="d-flex align-items-center">
                           <span>
                              <FormattedMessage
                                 id="dialog.shipping-costs.table.option.lego"
                                 defaultMessage="LEGO"
                              />
                           </span>
                           <InfoTooltip
                              text={intl.formatMessage({
                                 id: 'dialog.shipping-costs.info',
                                 defaultMessage:
                                    'Für die Berechnung werden auf Größe und Gewicht des Kartons jeweils 10% aufgeschlagen. Dies soll für Spielraum beim Verpacken sorgen.',
                              })}
                           />
                        </th>
                        <td className="text-center">{size}</td>
                        <td className="text-center">-</td>
                        <td className="text-center">
                           {(Math.round((carton?.weight ?? 0) / 100) / 10).toFixed(1)} kg
                        </td>
                     </tr>
                     {optionsTable}
                  </tbody>
               </Table>
            </div>
         </Accordion.Body>
      </Accordion.Item>
   );
};

// endregion
