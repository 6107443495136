import moment from 'moment';
import { IntlShape } from 'react-intl/src/types';
import { Colors } from '../components/atoms';
import { BlogPost, Category, Inventory, Label, LegoSet } from '../types/api';

export function convertToReadableTimestamp(intl: IntlShape, daysInPast: number) {
   if (daysInPast < 0) {
      return intl.formatMessage(
         {
            id: 'readable-duration.to-short',
            defaultMessage: '{days, plural, one {# Tag} other {# Tage}}!!!1elf',
         },
         { days: daysInPast }
      );
   }

   const duration = moment.duration(daysInPast, 'days');
   if (duration.years() > 0 && duration.months() > 0) {
      return intl.formatMessage(
         {
            id: 'readable-duration.year-and-month',
            defaultMessage:
               '{years, plural, one {# Jahr} other {# Jahre}} {months, plural, one {# Monat} other {# Monate}}',
         },
         {
            years: duration.years(),
            months: duration.months(),
         }
      );
   }

   if (duration.years() > 0) {
      return intl.formatMessage(
         {
            id: 'readable-duration.year',
            defaultMessage: '{years, plural, one {# Jahr} other {# Jahre}}',
         },
         {
            years: duration.years(),
         }
      );
   }

   if (duration.months() > 0 && duration.days() > 0) {
      return intl.formatMessage(
         {
            id: 'readable-duration.month-and-day',
            defaultMessage:
               '{months, plural, one {# Monat} other {# Monate}} {days, plural, one {# Tag} other {# Tage}}',
         },
         {
            months: duration.months(),
            days: duration.days(),
         }
      );
   }

   if (duration.months() > 0) {
      return intl.formatMessage(
         {
            id: 'readable-duration.month',
            defaultMessage: '{months, plural, one {# Monat} other {# Monate}}',
         },
         {
            months: duration.months(),
         }
      );
   }

   return intl.formatMessage(
      {
         id: 'readable-duration.day',
         defaultMessage: '{days, plural, one {# Tag} other {# Tage}}',
      },
      {
         days: duration.days(),
      }
   );
}

export function notEmpty<TValue>(value: TValue | null | undefined): value is TValue {
   return value !== null && value !== undefined;
}

export const formatDate = (date: Date | string | null | undefined, format: string): string =>
   moment(date).format(format);

export const getSelectStyles = (isInValid = false) => ({
   menu: (provided: Record<string, unknown>) => ({
      ...provided,
      color: Colors.gray_dark,
      zIndex: 4,
   }),
   placeholder: (provided: Record<string, unknown>) => ({
      ...provided,
      'white-space': 'nowrap',
      overflow: 'hidden',
   }),
   control: (provided: any) =>
      !isInValid
         ? {
              ...provided,
              color: '#000000',
           }
         : {
              ...provided,
              color: '#000000',
              borderColor: Colors.danger,
              '&:hover': { borderColor: Colors.danger },
           },
});

const encodeForRoutePath = (value: string | undefined | null) =>
   encodeURIComponent(value?.replaceAll(' ', '-') ?? '');

export const pageLinks = {
   legoSetDetail: (legoSet?: Partial<LegoSet>) => {
      if (!legoSet || !legoSet.number) return '';

      let path = `/legoSets/${encodeURIComponent(legoSet.number)}`;
      // Name hinzufügen, sofern vorhanden
      if (legoSet.name) path += `-${encodeForRoutePath(legoSet?.name)}`;

      return path;
   },
   legoSetsByLabel: (label?: Label) =>
      label ? `/label/${encodeForRoutePath(label.name_key)}` : '',
   legoSetsByCategory: (category?: Category) =>
      category ? `/category/${encodeForRoutePath(category.name_key)}` : '',

   editInventory: (legoSet?: LegoSet, inventory?: Partial<Inventory>) =>
      `/legoSets/${legoSet?.number}/inventory/${inventory?.id ?? 0}`,
   blogPostDetail: (blogPost?: Partial<BlogPost>, anchor: string = '') => {
      if (!blogPost || !blogPost.id) return '';

      let path = `/blog/${blogPost.id}`;
      // Subject hinzufügen, sofern vorhanden
      if (blogPost.subject) path += `-${encodeForRoutePath(blogPost?.subject)}`;
      if (anchor) path += `#${anchor}`;

      return path;
   },
};

export const convertToNumber = (value: string | undefined, defaultValue = 0) => {
   if (!value) return defaultValue;

   const num = parseInt(value, 10);

   return Number.isNaN(num) ? defaultValue : num;
};
