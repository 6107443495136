import React from 'react';
import Icon from '@mdi/react';
import { mdiBookshelf, mdiCurrencyEur, mdiStoreOutline } from '@mdi/js';
import { isMobile } from 'react-device-detect';
import { ColoredValue, Colors } from '../../atoms';
import { ContentWithPlaceholder, PriceTrendIcon } from '../../molecules';
import { LegoSetWithStats } from '../../../types/api/LegoSetWithStats';
import { PriceTrendChartPopover } from './PriceTrendChartPopover';
import { IconPropertyGroup, IconPropertyItem } from '../../molecules/IconPropertyGroup';

interface Props {
   legoSet: LegoSetWithStats;
}

export const CardBodyGeneric = ({ legoSet }: Props) =>
   isMobile ? (
      <>
         <div className="fs-4 d-flex align-items-center overflow-ellipsis" style={{ flex: 1 }}>
            <ColoredValue
               type="currency"
               value={legoSet.statistics.currentMarketValue.avg ?? legoSet.price}
               className="me-2"
            />
            <PriceTrendIcon
               marketValueHistory={legoSet.statistics.avgMarketValueHistory}
               size={1}
               type="neutral"
            />
         </div>
         <small className="d-flex flex-row">
            <div className="me-3 d-flex align-items-center">
               <Icon path={mdiStoreOutline} color={Colors.gray} size="1rem" className="me-1" />
               <span>{legoSet?.statistics.wanted.count || '-'}</span>
            </div>
            <div className="pe-3 d-flex align-items-center">
               <Icon path={mdiBookshelf} color={Colors.gray} size="1rem" className="me-1" />
               <span>{legoSet?.statistics.bought.count || '-'}</span>
            </div>
            <div className="pe-3 d-flex align-items-center">
               <Icon path={mdiCurrencyEur} color={Colors.gray} size="1rem" className="me-1" />
               <span>{legoSet?.statistics.sold.count || '-'}</span>
            </div>
         </small>
      </>
   ) : (
      <>
         <span className="pb-2 d-flex justify-content-between align-items-center">
            <span className="text-truncate small fst-italic">
               <ContentWithPlaceholder isLoading={!legoSet} width="5rem">
                  {legoSet.status_by_lego || <>&nbsp;</>}
               </ContentWithPlaceholder>
            </span>
         </span>
         <span className="card-body-price">
            <ColoredValue
               className="h3"
               type="currency"
               value={legoSet.statistics.currentMarketValue.avg ?? legoSet.price}
            />
            <PriceTrendChartPopover marketValueHistory={legoSet.statistics.avgMarketValueHistory}>
               <PriceTrendIcon
                  marketValueHistory={legoSet.statistics.avgMarketValueHistory}
                  size={1.5}
                  type="neutral"
               />
            </PriceTrendChartPopover>
         </span>
         <span className="flex-fill" />
         <IconPropertyGroup>
            <IconPropertyItem icon={mdiStoreOutline}>
               {legoSet.statistics.wanted.count || '-'}
            </IconPropertyItem>
            <IconPropertyItem icon={mdiBookshelf}>
               {legoSet.statistics.bought.count || '-'}
            </IconPropertyItem>
            <IconPropertyItem icon={mdiCurrencyEur}>
               {legoSet.statistics.sold.count || '-'}
            </IconPropertyItem>
         </IconPropertyGroup>
      </>
   );
