import { Badge, Container, ListGroup } from 'react-bootstrap';
import React, { useEffect, useState } from 'react';
import { Link, useParams } from 'react-router-dom';
import orderBy from 'lodash/orderBy';
import Icon from '@mdi/react';
import { mdiChevronRight } from '@mdi/js/commonjs/mdi';
import { showBackButton } from '../../redux/appSlice';
import { LegoSetsGridList } from '../../components/organisms/LegoSetsGridList';
import { useAppDispatch, useCache, useMemorizedIntl } from '../../hooks';
import { Category } from '../../types/api';
import { CategoryModel } from '../../models/CategoryModel';
import { getInventoryByCategory } from '../../redux/pagesSlice';
import { Colors, ContentLoader } from '../../components/atoms';
import { PageTitle } from '../../components/organisms/PageTitle';
import { pageLinks } from '../../utils';

export const LegoSetsByCategoryPage = () => {
   const intl = useMemorizedIntl();
   const dispatch = useAppDispatch();
   const { categories } = useCache();
   const routeParams = useParams<{ name?: string }>();
   const [category, setCategory] = useState<Category | null>(null);

   useEffect(() => {
      (async () => {
         if (!routeParams.name) {
            // Anzeigen alle Kategorien
            dispatch(showBackButton(false));
            setCategory(null);
         } else {
            // Lade Sets der angegebenen Kategorie
            dispatch(showBackButton(true));
            setCategory((await CategoryModel.list({ name_key: routeParams.name }))[0]);
         }
      })();
   }, [routeParams.name, dispatch]);

   useEffect(() => {
      (async () => {
         if (!category) return;

         dispatch(getInventoryByCategory(category.id));
      })();
   }, [category, dispatch]);

   if (!category && routeParams.name) return <ContentLoader />;

   if (!category) {
      return (
         <Container>
            <PageTitle
               title={intl.formatMessage({
                  id: 'category.title.all',
                  defaultMessage: 'Alle Kategorien',
               })}
            />
            <ListGroup>
               {orderBy(categories, 'name')
                  .filter(c => c.legoSets.length > 0)
                  .map(c => (
                     <ListGroup.Item
                        as={Link}
                        to={pageLinks.legoSetsByCategory(c)}
                        key={c.id}
                        className="d-flex flex-row align-items-center pe-0"
                        action
                     >
                        <span className="flex-fill">{c.name}</span>
                        <Badge className="badge-lg" pill bg="secondary">
                           {c.legoSets.length}
                        </Badge>
                        <div className="d-flex align-items-center px-1">
                           <Icon path={mdiChevronRight} color={Colors.link} size={1} />
                        </div>
                     </ListGroup.Item>
                  ))}
            </ListGroup>
         </Container>
      );
   }

   return (
      <Container>
         <PageTitle
            title={intl.formatMessage(
               {
                  id: 'category.headline',
                  defaultMessage: 'Sets der Kategorie: {name}',
               },
               {
                  name: category.name,
               }
            )}
            appTitle={category.name}
            subtitle={intl.formatMessage({
               id: 'category.title',
               defaultMessage: 'Kategorie',
            })}
         />
         <LegoSetsGridList hideOrdering lockedCategoryId={category.id} />
      </Container>
   );
};
