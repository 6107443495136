import React from 'react';
import { ButtonGroup } from 'react-bootstrap';
import { isMobile } from 'react-device-detect';
import { FormattedMessage } from 'react-intl';
import { LinkButton } from '../../../components/molecules';

import './InventoryButtonGroup.scss';
import { InventoryType } from '../../../types/api';

interface Props {
   /**
    * The current active tab.
    */
   activeTab: InventoryType;
}

/**
 * Creates a button group as a tab panel for the different inventory types.
 * When clicked on a button the user gets routed to the specific page for the inventory type.
 */
export const InventoryButtonGroup = (props: Props) => {
   if (!isMobile) return null;

   return (
      <ButtonGroup
         className="d-flex button-tabs pb-2"
         style={{ maxWidth: '40rem', marginLeft: 'auto', marginRight: 'auto' }}
      >
         <TabButton activeTab={props.activeTab} inventoryType="wanted">
            <FormattedMessage id="inventory-tabs.wanted" defaultMessage="Wunsch" />
         </TabButton>
         <TabButton activeTab={props.activeTab} inventoryType="bought">
            <FormattedMessage id="inventory-tabs.bought" defaultMessage="Inventar" />
         </TabButton>
         <TabButton activeTab={props.activeTab} inventoryType="sold">
            <FormattedMessage id="inventory-tabs.sold" defaultMessage="Verkauft" />
         </TabButton>
      </ButtonGroup>
   );
};

interface TabProps {
   activeTab: InventoryType;
   inventoryType: InventoryType;
   children: React.ReactNode;
}

/**
 * Creates a tab element.
 */
const TabButton = ({ activeTab, inventoryType, children }: TabProps) => (
   <LinkButton
      to={`/inventory/${inventoryType}`}
      variant={activeTab === inventoryType ? 'success' : 'outline-secondary'}
   >
      {children}
   </LinkButton>
);
