import { FormattedMessage } from 'react-intl';
import React, { useCallback, useState } from 'react';
import { toast } from 'react-toastify';
import { Card, Dropdown } from 'react-bootstrap';
import Icon from '@mdi/react';
import { mdiDotsHorizontal } from '@mdi/js';
import { Colors } from '../atoms';
import { CommentForm } from './CommentForm';
import { ConfirmDeleteDialog } from '../dialogs/ConfirmDeleteDialog';
import { AdminDropdownItem } from '../molecules';
import { useMemorizedIntl, useSession } from '../../hooks';
import { BlogPostComment, ROLE_ADMIN } from '../../types/api';
import { BlogPostCommentModel } from '../../models/BlogPostCommentModel';
import { ReportDialog } from './ReportDialog';
import { ReportedInfosDialog } from './ReportedInfosDialog';
import { ReplyDialog } from './ReplyDialog';
import { formatDate } from '../../utils';

interface Props {
   comment: BlogPostComment;
   onRefresh: () => Promise<void>;
}

export const CommentCard = ({ comment, onRefresh }: Props) => {
   const intl = useMemorizedIntl();
   const { sessionUser, sessionRoles } = useSession();
   const [editMode, setEditMode] = useState(false);
   const [showDeleteDialog, setShowDeleteDialog] = useState(false);
   const [showReportDialog, setShowReportDialog] = useState(false);
   const [showReportReasonDialog, setShowReportReasonDialog] = useState(false);
   const [showReplyDialog, setShowReplyDialog] = useState(false);

   const handleDelete = useCallback(
      async (adminMode = false) => {
         comment.is_deleted = true;
         await BlogPostCommentModel.update(comment);
         await onRefresh();

         setShowDeleteDialog(false);

         if (!adminMode) {
            toast.success(
               intl.formatMessage({
                  id: 'blog.blog-post-comment.delete-success-toast',
                  defaultMessage: 'Dein Kommentar wurde erfolgreich gelöscht.',
               })
            );
         }
      },
      [comment, intl, onRefresh]
   );

   const handleRestore = useCallback(async () => {
      comment.is_deleted = false;
      await BlogPostCommentModel.update(comment);
      await onRefresh();
   }, [comment, onRefresh]);

   const handleDeletePermanent = useCallback(async () => {
      await BlogPostCommentModel.delete(comment);
      await onRefresh();
   }, [comment, onRefresh]);

   return (
      <>
         <div className="d-flex flex-column">
            <Card
               className={`mb-4 ${comment.is_deleted ? 'blog-post-comment-deleted' : ''} ${
                  !comment.is_deleted && comment.is_reported ? 'blog-post-comment-reported' : ''
               }
            `}
            >
               <Card.Header className="py-1 ps-3 pe-1 text-muted d-flex align-items-center justify-content-between">
                  <div>
                     <FormattedMessage
                        id="blog.blog-post-comment.published-from"
                        defaultMessage="<strong>{user}</strong> am {date}"
                        values={{
                           user: comment.nickname,
                           date: formatDate(comment.date, 'L LTS'),
                        }}
                     />
                     {comment.is_edited && (
                        <span className="ps-2 fst-italic small">
                           <FormattedMessage
                              id="blog.blog-post-comment.edited-text"
                              defaultMessage="Bearbeitet"
                           />
                        </span>
                     )}
                  </div>
                  <div className="d-flex">
                     <Dropdown>
                        <Dropdown.Toggle variant="secondary" className="px-1 title-button">
                           <Icon path={mdiDotsHorizontal} size={1} color={Colors.white} />
                        </Dropdown.Toggle>

                        <Dropdown.Menu>
                           {!comment.is_deleted && !comment.is_reported && (
                              <Dropdown.Item onClick={() => setShowReplyDialog(true)}>
                                 <FormattedMessage
                                    id="blog.blog-post-comment.button.reply"
                                    defaultMessage="Antworten"
                                 />
                              </Dropdown.Item>
                           )}
                           {/* Es können nur Kommentare von anderen Accounts gemeldet werden */}
                           {/* eslint-disable-next-line eqeqeq */}
                           {comment.user_id != sessionUser?.id &&
                              !comment.is_deleted &&
                              !comment.is_reported && (
                                 <Dropdown.Item onClick={() => setShowReportDialog(true)}>
                                    <FormattedMessage
                                       id="blog.blog-post-comment.button.report"
                                       defaultMessage="Kommentar melden"
                                    />
                                 </Dropdown.Item>
                              )}
                           {/* Es können nur eigene Kommentare bearbeitet werden */}
                           {/* eslint-disable-next-line eqeqeq */}
                           {comment.user_id == sessionUser?.id &&
                              !comment.is_deleted &&
                              !comment.is_reported && (
                                 <>
                                    <Dropdown.Item onClick={() => setEditMode(true)}>
                                       <FormattedMessage
                                          id="button.edit"
                                          defaultMessage="Bearbeiten"
                                       />
                                    </Dropdown.Item>

                                    <Dropdown.Item
                                       style={{ color: Colors.danger }}
                                       onClick={() => setShowDeleteDialog(true)}
                                    >
                                       <FormattedMessage
                                          id="button.delete"
                                          defaultMessage="Löschen"
                                       />
                                    </Dropdown.Item>
                                 </>
                              )}
                           {sessionRoles.includes(ROLE_ADMIN) && (
                              <>
                                 <Dropdown.Divider />
                                 {comment.is_deleted && (
                                    <>
                                       <AdminDropdownItem
                                          style={{ color: Colors.success }}
                                          onClick={() => handleRestore()}
                                       >
                                          <FormattedMessage
                                             id="button.restore-comment"
                                             defaultMessage="Kommentar wiederherstellen"
                                          />
                                       </AdminDropdownItem>
                                       <AdminDropdownItem
                                          style={{ color: Colors.danger }}
                                          onClick={() => handleDeletePermanent()}
                                       >
                                          <FormattedMessage
                                             id="button.delete-comment-permanent"
                                             defaultMessage="Kommentar dauerhaft löschen"
                                          />
                                       </AdminDropdownItem>
                                    </>
                                 )}
                                 {comment.is_reported && (
                                    <AdminDropdownItem
                                       onClick={() => setShowReportReasonDialog(true)}
                                    >
                                       <FormattedMessage
                                          id="button.show-report"
                                          defaultMessage="Meldung anzeigen"
                                       />
                                    </AdminDropdownItem>
                                 )}
                                 {!comment.is_deleted && (
                                    <AdminDropdownItem
                                       style={{ color: Colors.danger }}
                                       onClick={() => handleDelete(true)}
                                    >
                                       <FormattedMessage
                                          id="button.delete"
                                          defaultMessage="Löschen"
                                       />
                                    </AdminDropdownItem>
                                 )}
                              </>
                           )}
                        </Dropdown.Menu>
                     </Dropdown>
                  </div>
               </Card.Header>
               <Card.Body className="p-3">
                  {editMode ? (
                     <CommentForm
                        comment={comment}
                        afterSubmit={async () => {
                           setEditMode(false);
                           await onRefresh();
                        }}
                        onCancel={() => setEditMode(false)}
                     />
                  ) : (
                     <Card.Text style={{ whiteSpace: 'pre-line' }}>{comment.comment}</Card.Text>
                  )}
               </Card.Body>
            </Card>
         </div>

         <ConfirmDeleteDialog
            show={showDeleteDialog}
            onClose={() => setShowDeleteDialog(false)}
            onDelete={handleDelete}
         >
            <p>
               <FormattedMessage
                  id="dialog.delete-comment.text"
                  defaultMessage="Möchtest du den Kommentar vom {date} wirklich löschen?"
                  values={{
                     date: formatDate(comment.date, 'L LTS'),
                  }}
               />
            </p>
         </ConfirmDeleteDialog>
         <ReportedInfosDialog
            comment={comment}
            show={showReportReasonDialog}
            onClose={() => setShowReportReasonDialog(false)}
            refresh={onRefresh}
         />
         <ReportDialog
            comment={comment}
            show={showReportDialog}
            onClose={() => setShowReportDialog(false)}
            refresh={onRefresh}
         />
         <ReplyDialog
            comment={comment}
            show={showReplyDialog}
            onClose={() => setShowReplyDialog(false)}
            refresh={onRefresh}
         />
      </>
   );
};
