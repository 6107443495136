import React, { ReactNode } from 'react';
import { Button, Card } from 'react-bootstrap';
import { LinkContainer } from 'react-router-bootstrap';
import { isMobile } from 'react-device-detect';
import Icon from '@mdi/react';
import { mdiChevronRight } from '@mdi/js/commonjs/mdi';
import { Colors, SkeletonPlaceholder } from '../../atoms';
import { ContentWithPlaceholder } from '../../molecules';
import { LegoSetImage } from '../../molecules/LegoSetImage';
import { InventoryType, LegoSet } from '../../../types/api';
import { CardBodyBought } from './CardBodyBought';
import { CardBodySold } from './CardBodySold';
import { CardBodyGeneric } from './CardBodyGeneric';
import { CardBodyWanted } from './CardBodyWanted';
import { CardBodyInventory } from './CardBodyInventory';
import { InventoryWithStats } from '../../../types/api/InventoryWithStats';
import { LegoSetWithStats } from '../../../types/api/LegoSetWithStats';

import './LegoSetCard.scss';
import { pageLinks } from '../../../utils';

interface Props {
   legoSet?: LegoSetWithStats;
   inventory?: InventoryWithStats;
   displayContext?: InventoryType | 'inventory';
   onClick?: () => void;
}

export const LegoSetCard = ({ displayContext, legoSet, inventory, onClick }: Props) => {
   const isLoading = !legoSet && !inventory;
   const ls = legoSet ?? inventory?.legoSet;

   return isMobile || displayContext === 'inventory' ? (
      <MobileTemplate legoSet={legoSet ?? inventory?.legoSet} onClick={onClick}>
         {isLoading ? (
            <div className="d-flex flex-column flex-fill justify-content-between">
               <SkeletonPlaceholder width="80%" height="1.5rem" />
               <SkeletonPlaceholder width="50%" height="1.5rem" />
               <SkeletonPlaceholder width="60%" height="1rem" />
            </div>
         ) : (
            <>
               <h4 className="mb-0 overflow-ellipsis">{`${ls?.number} - ${ls?.name}`}</h4>
               {legoSet && displayContext === 'wanted' && <CardBodyWanted legoSet={legoSet} />}
               {legoSet && displayContext === 'bought' && <CardBodyBought legoSet={legoSet} />}
               {legoSet && displayContext === 'sold' && <CardBodySold legoSet={legoSet} />}
               {legoSet && !displayContext && <CardBodyGeneric legoSet={legoSet} />}
               {inventory && displayContext === 'inventory' && (
                  <CardBodyInventory inventory={inventory} />
               )}
            </>
         )}
      </MobileTemplate>
   ) : (
      <BrowserTemplate
         legoSet={legoSet}
         displayContext={displayContext ?? 'generic'}
         onClick={onClick}
      >
         {isLoading && (
            <div>
               <SkeletonPlaceholder width="6rem" />
               <SkeletonPlaceholder width="12rem" height="2.5rem" />
               <SkeletonPlaceholder width="3rem" />
               <SkeletonPlaceholder width="9rem" />
            </div>
         )}
         {legoSet && displayContext === 'wanted' && <CardBodyWanted legoSet={legoSet} />}
         {legoSet && displayContext === 'bought' && <CardBodyBought legoSet={legoSet} />}
         {legoSet && displayContext === 'sold' && <CardBodySold legoSet={legoSet} />}
         {legoSet && !displayContext && <CardBodyGeneric legoSet={legoSet} />}
      </BrowserTemplate>
   );
};

interface BrowserTemplateProps {
   legoSet?: LegoSet;
   children: ReactNode;
   displayContext?: InventoryType | 'inventory' | 'generic';
   onClick?: () => void;
}

export const BrowserTemplate = ({
   legoSet,
   children,
   displayContext,
   onClick,
}: BrowserTemplateProps) => (
   <LinkContainer to={pageLinks.legoSetDetail(legoSet)} onClick={onClick}>
      <Card className="card-legoset card-clickable" as={Button}>
         <Card.Header className="text-truncate">
            <ContentWithPlaceholder isLoading={!legoSet} width="10rem">
               {`${legoSet?.number} - ${legoSet?.name}`}
            </ContentWithPlaceholder>
         </Card.Header>
         <div className="card-image card-img-top">
            {legoSet?.category_name && (
               <div className="category-overlay text-nowrap overflow-hidden">
                  {legoSet?.category_name}
               </div>
            )}
            <LegoSetImage legoSet={legoSet} thumbnail />
         </div>
         <Card.Body className={`inventory-type-${displayContext}`}>{children}</Card.Body>
      </Card>
   </LinkContainer>
);

interface MobileTemplateProps {
   legoSet?: LegoSet;
   children: ReactNode;
   onClick?: () => void;
}

export const MobileTemplate = ({ legoSet, children, onClick }: MobileTemplateProps) => (
   <LinkContainer to={pageLinks.legoSetDetail(legoSet)} onClick={onClick}>
      <Card as={Button} className="card-clickable mobile my-1">
         <Card.Body className="p-0 d-flex" style={{ height: '6.25rem' }}>
            <div
               className="p-1 d-flex align-items-center justify-content-center"
               style={{
                  width: '5.5rem',
                  backgroundColor: '#373737',
                  borderTopLeftRadius: '0.25rem',
                  borderBottomLeftRadius: '0.25rem',
               }}
            >
               <LegoSetImage legoSet={legoSet} thumbnail />
            </div>
            <div className="d-flex flex-column flex-fill pt-1 ps-2 pb-1" style={{ width: 0 }}>
               {children}
            </div>
            <div className="d-flex align-items-center">
               <Icon path={mdiChevronRight} color={Colors.link} size={1} />
            </div>
         </Card.Body>
      </Card>
   </LinkContainer>
);
