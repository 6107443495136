import React, { useEffect } from 'react';
import { Badge, Container, ListGroup } from 'react-bootstrap';
import { Link } from 'react-router-dom';
import Icon from '@mdi/react';
import { mdiChevronRight } from '@mdi/js/commonjs/mdi';
import { showBackButton } from '../../../redux/appSlice';
import { Image } from '../../../components/molecules';
import { UserModel } from '../../../models/UserModel';
import { formatDate } from '../../../utils';
import { useAppDispatch } from '../../../hooks';
import { PageTitle } from '../../../components/organisms/PageTitle';
import { Colors, ContentLoader } from '../../../components/atoms';
import { Pagination } from '../../../components/organisms/Pagination';

export const UsersAdminPage = () => {
   const dispatch = useAppDispatch();

   useEffect(() => {
      dispatch(showBackButton(true));
   }, [dispatch]);

   return (
      <Pagination model={UserModel} initialPageSize={50}>
         {({ items, maxItemCount }) => (
            <Container>
               <PageTitle title={`Benutzer (${maxItemCount})`} subtitle="Administration" />
               <ListGroup className="mb-3">
                  {items === null ? (
                     <ListGroup.Item>
                        <ContentLoader style={{ height: '4rem' }} />
                     </ListGroup.Item>
                  ) : (
                     items?.map(u => (
                        <ListGroup.Item
                           key={u.id}
                           as={Link}
                           to={`/admin/users/${u.id}`}
                           className="d-flex flex-row gap-2"
                           action
                        >
                           <Image
                              url={u.picture_url}
                              style={{
                                 width: '6.25rem',
                                 height: '6,25rem',
                                 aspectRatio: '1',
                                 objectFit: 'contain',
                              }}
                              alt="Profil-Bild"
                           />
                           <div className="d-flex flex-column flex-fill">
                              <div className="fs-4">
                                 {u.name} ({u.locale})
                              </div>
                              <div className="text-muted">{u.email !== '' ? u.email : ' '}</div>
                              <div className="text-muted">
                                 Letzter Login: {formatDate(u.last_logon, 'L')}
                              </div>
                              <div>
                                 {!u.enabled && (
                                    <Badge bg="danger" className="me-1">
                                       deaktiviert
                                    </Badge>
                                 )}
                                 {u.roles.map(r => (
                                    <Badge key={r} bg="primary" className="me-1">
                                       {r}
                                    </Badge>
                                 ))}
                              </div>
                           </div>
                           <div className="d-flex align-items-center">
                              <Icon path={mdiChevronRight} color={Colors.link} size={1} />
                           </div>
                        </ListGroup.Item>
                     ))
                  )}
               </ListGroup>
            </Container>
         )}
      </Pagination>
   );
};
