import { Dropdown, Form, FormControl } from 'react-bootstrap';
import React, {
   CSSProperties,
   useCallback,
   useEffect,
   useLayoutEffect,
   useMemo,
   useRef,
   useState,
} from 'react';
import { useNavigate } from 'react-router-dom';
import debounce from 'lodash/debounce';
import { LinkContainer } from 'react-router-bootstrap';
import { FormattedMessage } from 'react-intl';
import { Colors } from '../atoms';
import { LegoSet } from '../../types/api';
import { LegoSetModel } from '../../models/LegoSetModel';
import { useMemorizedIntl } from '../../hooks';
import { pageLinks } from '../../utils';

interface Props {
   className?: string;
   style?: CSSProperties;
   onSearchResultClick?: () => void;
   focus?: boolean;
}

// TODO: Dropdown-Vorschläge per Cursor-Tasten bedienbar machen (Im Standard geht es, nur in dieser Implementierung nicht)
export const SearchForm = ({ className, style, onSearchResultClick, focus }: Props) => {
   const navigate = useNavigate();
   const intl = useMemorizedIntl();
   const [resultSuggestions, setSearchSuggestions] = useState<LegoSet[] | null>(null);
   const [query, setQuery] = useState('');
   const searchInputRef = useRef<any>(null);

   useLayoutEffect(() => {
      if (focus) searchInputRef.current?.focus();
   }, [focus]);

   const handleSubmitForm = useCallback(
      (event: any) => {
         event.preventDefault();

         if (!query) return;

         navigate(`/search/${encodeURI(query)}`);
         onSearchResultClick?.();
      },
      [navigate, onSearchResultClick, query]
   );

   const loadData = useMemo(
      () =>
         debounce(async (text: string) => {
            if (!text) setSearchSuggestions(null);
            else setSearchSuggestions(await LegoSetModel.search(text, 'number', 'ASC', 4));
         }, 200),
      []
   );

   useEffect(() => {
      (async () => {
         loadData(query);
      })();
   }, [loadData, query]);

   const markSearchResult = (text: string) => {
      const strArr = text.split(new RegExp(`(${query})`, 'ig'));
      return strArr.map(ea => {
         if (ea.toLowerCase() === query.toLowerCase()) {
            return (
               <span key={ea} style={{ backgroundColor: Colors.success }}>
                  {ea}
               </span>
            );
         }
         return ea;
      });
   };

   return (
      <Form id="search-form" className={className} style={style} onSubmit={handleSubmitForm}>
         <FormControl
            ref={searchInputRef}
            type="text"
            className="w-100"
            placeholder={intl.formatMessage({
               id: 'navbar.search.placeholder',
               defaultMessage: 'Suchen',
            })}
            value={query}
            onChange={e => setQuery(e.target.value)}
         />
         <Dropdown key={query} autoClose={false}>
            <div className="flex-fill" />
            <Dropdown.Menu show={!!resultSuggestions}>
               {resultSuggestions?.map(s => (
                  <LinkContainer
                     key={s.id}
                     to={pageLinks.legoSetDetail(s)}
                     onClick={onSearchResultClick}
                  >
                     <Dropdown.Item className="d-flex flex-column">
                        <small className="fst-italic text-light">{s.category_name}</small>
                        <span>{markSearchResult(`${s.number}: ${s.name}`)}</span>
                     </Dropdown.Item>
                  </LinkContainer>
               ))}
               {resultSuggestions && resultSuggestions.length > 0 && (
                  <>
                     <Dropdown.Divider />
                     <LinkContainer
                        to={`/search/${encodeURI(query)}`}
                        onClick={onSearchResultClick}
                     >
                        <Dropdown.Item className="d-flex justify-content-center">
                           <span>
                              <FormattedMessage
                                 id="navbar.search.show-all"
                                 defaultMessage="Alle Ergebnisse anzeigen"
                              />
                           </span>
                        </Dropdown.Item>
                     </LinkContainer>
                  </>
               )}
               {resultSuggestions && resultSuggestions.length === 0 && (
                  <Dropdown.Item className="d-flex justify-content-center fst-italic">
                     <span>
                        <FormattedMessage
                           id="navbar.search.no-results"
                           defaultMessage="Keine Ergebnisse gefunden"
                        />
                     </span>
                  </Dropdown.Item>
               )}
            </Dropdown.Menu>
         </Dropdown>
      </Form>
   );
};
