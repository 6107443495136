import { Form } from 'react-bootstrap';
import { FormattedMessage } from 'react-intl';
import React, { useCallback } from 'react';
import { FormikErrors, FormikProps } from 'formik';
import { User } from '../../types/api';
import { UserModel } from '../../models/UserModel';
import { TextControl } from '../forms';
import { useMemorizedIntl, useValidationErrors } from '../../hooks';

interface Props {
   formik: Pick<
      FormikProps<Partial<User>>,
      | 'errors'
      | 'isValid'
      | 'touched'
      | 'values'
      | 'handleChange'
      | 'handleBlur'
      | 'isSubmitting'
      | 'setFieldValue'
      | 'submitCount'
   >;
}

export const FormPartPublicName = ({ formik }: Props) => {
   const intl = useMemorizedIntl();

   return (
      <TextControl
         formik={formik}
         name="public_name"
         type="compact"
         label={intl.formatMessage({
            id: 'user.profile.public_name.label',
            defaultMessage: 'Nickname',
         })}
         placeholder={intl.formatMessage({
            id: 'user.profile.public_name.placeholder',
            defaultMessage: 'Nickname',
         })}
         helpText={
            <Form.Text className="text-muted">
               <FormattedMessage
                  id="user.profile.public_name.hint"
                  defaultMessage="Dein Nickname muss zwischen 1 und 128 Zeichen lang sein. Erlaubt sind Buchstaben, Zahlen und die Sonderzeichen: ! § $ % & * # - _ . sowie Leerzeichen."
               />
            </Form.Text>
         }
      />
   );
};

export const useValidation = () => {
   const intl = useMemorizedIntl();
   const validationErrors = useValidationErrors();

   return useCallback(
      (values: User) => {
         const errors: FormikErrors<User> = {};

         if (!values.public_name) errors.public_name = validationErrors.required;

         if (
            (values.public_name?.length ?? 0) > 0 &&
            !values.public_name?.match(/^[a-zA-Z0-9!§$%&*#\-_.\s]{1,128}$/)
         )
            errors.public_name = intl.formatMessage({
               id: 'user.profile.public_name.invalid',
               defaultMessage: 'Dein Nickname beinhaltet ungültige Zeichen.',
            });

         if ((values.public_name?.length ?? 0) > 128)
            errors.public_name = intl.formatMessage({
               id: 'user.profile.public_name.to-long',
               defaultMessage: 'Dein Nickname darf maximal 128 Zeichen lang sein.',
            });

         if (!errors.public_name && values.public_name) {
            // Wir prüfen die Namen nur, wenn es bisher kein Fehler gab
            const nameIsUnique = UserModel.nameIsUnique(values.public_name);
            if (!nameIsUnique)
               errors.public_name = intl.formatMessage({
                  id: 'user.profile.public_name.already-taken',
                  defaultMessage:
                     'Dein Nickname wird bereits verwendet. Bitte gebe einen Anderen an.',
               });
         }

         return errors;
      },
      [intl, validationErrors]
   );
};
