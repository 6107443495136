import Icon from '@mdi/react';
import React, { ReactNode } from 'react';
import { Colors } from '../atoms';
import './IconPropertyGroup.scss';

interface Props {
   children: ReactNode;
}
export const IconPropertyGroup = ({ children }: Props) => (
   <span className="icon-property-group">{children}</span>
);

interface IconPropertyItemProps {
   icon?: string;
   label?: string;
   children: ReactNode;
}

export const IconPropertyItem = ({ icon, label, children }: IconPropertyItemProps) => (
   <span>
      {icon && <Icon path={icon} color={Colors.gray} size={1} />}
      {label && <span className="text-muted">{label}</span>}
      <span className="fs-5">{children}</span>
   </span>
);
