import { Alert, Container } from 'react-bootstrap';
import React, { useCallback, useEffect, useState } from 'react';
import { FormattedMessage } from 'react-intl';
import moment from 'moment';
import { BlogPostEntry } from '../../components/blog/BlogPostEntry';
import { AdminButton, LoadingButton } from '../../components/molecules';
import {
   setActiveTab,
   setNewsBadge,
   showBackButton,
   updateSessionUser,
} from '../../redux/appSlice';
import { useAppDispatch, useMemorizedIntl, useSession } from '../../hooks';
import { BlogPost, ROLE_ADMIN } from '../../types/api';
import { BlogPostModel } from '../../models/BlogPostModel';
import { UserModel } from '../../models/UserModel';
import { ShowIfRole } from '../../components/molecules/ShowIfRole';
import { PageTitle } from '../../components/organisms/PageTitle';

export const BlogPage = () => {
   const dispatch = useAppDispatch();
   const intl = useMemorizedIntl();
   const { sessionUser } = useSession();
   const [blogPosts, setBlogPosts] = useState<BlogPost[]>([]);

   const loadBlogPosts = useCallback(async () => {
      setBlogPosts(await BlogPostModel.list({}, 'publish_date', 'DESC'));
   }, []);

   // Init Page
   useEffect(() => {
      (async () => {
         dispatch(showBackButton(false));
         dispatch(setActiveTab('Blog'));
         await loadBlogPosts();
      })();
   }, [dispatch, loadBlogPosts]);

   // Mark 'New Blog Post' as read
   useEffect(() => {
      (async () => {
         if (sessionUser && blogPosts.length > 0) {
            const lastBlogVisit = sessionUser?.last_blog_visit
               ? moment(sessionUser?.last_blog_visit).valueOf()
               : 0;

            // Es existiert min. ein Blogeintrag nach dem Zeitpunkt des letzten Betrachtens, also aktualisieren wir
            // dies beim User
            if (
               blogPosts.some(
                  p => (p.publish_date ? moment(p.publish_date).valueOf() : 0) > lastBlogVisit
               )
            ) {
               const changedUser = {
                  ...sessionUser,
                  last_blog_visit: moment().toISOString(),
               };
               dispatch(updateSessionUser(await UserModel.update(changedUser)));
               dispatch(setNewsBadge(0));
            }
         }
      })();
   }, [sessionUser, blogPosts, dispatch]);

   const handleActivateBlogUpdates = useCallback(async () => {
      if (!sessionUser) return;

      const changedUser = {
         ...sessionUser,
         blog_updates_enabled: true,
      };
      dispatch(updateSessionUser(await UserModel.update(changedUser)));
   }, [dispatch, sessionUser]);

   return (
      <Container>
         <PageTitle
            title={intl.formatMessage({ id: 'blog.title', defaultMessage: 'Blog' })}
            hidden
         />
         <ShowIfRole roles={[ROLE_ADMIN]}>
            <div className="mb-4 d-flex justify-content-start">
               <AdminButton to="/blog/new" variant="secondary">
                  <FormattedMessage
                     id="blog.button.create-new-blog-post"
                     defaultMessage="Neuen Blog-Eintrag erstellen"
                  />
               </AdminButton>
            </div>
         </ShowIfRole>
         {sessionUser && !sessionUser.blog_updates_enabled && (
            <Alert variant="primary">
               <div>
                  <FormattedMessage
                     id="blog.subscribe-to-blog"
                     defaultMessage="Wenn du bei neuen Blog-Einträgen eine E-Mail-Benachrichtigung erhalten möchtest, dann kannst du dies hier aktivieren."
                  />
               </div>
               <LoadingButton variant="link" className="p-0" onClick={handleActivateBlogUpdates}>
                  <FormattedMessage
                     id="blog.button.enable-email-updates"
                     defaultMessage="E-Mail-Benachrichtigung aktivieren"
                  />
               </LoadingButton>
            </Alert>
         )}
         {blogPosts.map(p => (
            <div key={p.id} className="mb-5">
               <BlogPostEntry post={p} onRefresh={() => loadBlogPosts()} />
            </div>
         ))}
      </Container>
   );
};
