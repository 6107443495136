import { mdiNewspaperVariantOutline, mdiPuzzleEditOutline } from '@mdi/js';
import { useAppSelector, useMemorizedIntl } from '../hooks';

export type Notification = {
   title: string;
   message: string;
   icon: string;
   buttonLabel?: string;
   navigateTo?: string;
};

export const useNotifications = (): Notification[] => {
   const intl = useMemorizedIntl();
   const badgeNews = useAppSelector(s => s.app.badges.news);
   const changeRequests = useAppSelector(s => s.app.notifications.changeRequests);

   const notifications: Notification[] = [];

   if (badgeNews > 0) {
      notifications.push({
         title: intl.formatMessage({
            id: 'notifications.new-blog-post.title',
            defaultMessage: 'Neuer Blog-Beitrag',
         }),
         message: intl.formatMessage({
            id: 'notifications.new-blog-post.message',
            defaultMessage: 'Es existiert ein neuer Beitrag im Blog.',
         }),
         icon: mdiNewspaperVariantOutline,
         buttonLabel: intl.formatMessage({
            id: 'notifications.new-blog-post.button',
            defaultMessage: 'Zum Blog',
         }),
         navigateTo: '/blog',
      });
   }

   changeRequests.forEach(cr => {
      notifications.push({
         title: intl.formatMessage({
            id: 'notifications.change-request.title',
            defaultMessage: 'Neuer Änderungsantrag',
         }),
         message: intl.formatMessage(
            {
               id: 'notifications.change-request.message',
               defaultMessage:
                  'Für das LEGO-Set <em><strong>{set_name}</strong></em> wurde von <em><strong>{user_name}</strong></em> eine Änderung der Stammdaten beantragt.',
            },
            {
               set_name: cr.set_name,
               user_name: cr.user_name,
            }
         ),
         icon: mdiPuzzleEditOutline,
         buttonLabel: intl.formatMessage({
            id: 'notifications.change-request.button',
            defaultMessage: 'Antrag anzeigen',
         }),
         navigateTo: `/admin/legoSets/changeRequests/${cr.id}`,
      });
   });
   /*
   for (let i = 0; i < 10; i++) {
      notifications.push({
         title: "Test",
         message: "Test Test",
         icon: mdiNewspaperVariantOutline,
      });
   }
*/
   return notifications;
};
