import React, { useEffect, useState } from 'react';
import { Alert, Col, Container, Row } from 'react-bootstrap';
import { isMobile } from 'react-device-detect';
import { mdiPlusThick } from '@mdi/js';
import Icon from '@mdi/react';
import { FormattedMessage } from 'react-intl';
import { useParams } from 'react-router-dom';
import { Tags } from './Tags';
import { LegoSetPropertiesTable } from './LegoSetPropertiesTable';
import { LegoSetPriceChart } from './LegoSetPriceChart';
import { PriceCalculatorDialogButton } from './PriceCalculator';
import { InventoryList } from './InventoryList';
import { loadLegoSet } from '../../../redux/pagesSlice';
import { Card, ContentWithPlaceholder, ResponsiveButton } from '../../../components/molecules';
import { showBackButton } from '../../../redux/appSlice';
import { Colors, ContentLoader } from '../../../components/atoms';
import { ShippingCostsDialogButton } from './ShippingCostsDialogButton';
import { useAppDispatch, useAppSelector, useMemorizedIntl } from '../../../hooks';
import { Inventory } from '../../../types/api';
import { PageNotFoundPage } from '../../PageNotFoundPage';
import { EditInventoryDialog } from '../../../components/dialogs/EditInventoryDialog';
import { newInventoryObject } from '../../../forms/InventoryForm';
import './LegoSetPage.scss';
import { PageTitle } from '../../../components/organisms/PageTitle';
import { LegoSetModel } from '../../../models/LegoSetModel';
import { pageLinks } from '../../../utils';
import { PriceCard } from './PriceCard';

export const LegoSetPage = () => {
   const intl = useMemorizedIntl();
   const routeParams = useParams<{ numberAndName?: string }>();
   const dispatch = useAppDispatch();
   const { legoSet: legoSetFromState, loading } = useAppSelector(s => s.pages.legoSet);
   const [inventoryToEdit, setInventoryToEdit] = useState<Partial<Inventory>>();

   const [numberFromRouteParams] = routeParams?.numberAndName?.split('-') ?? [];

   // Da das LegoSet im State zwischengespeichert wird, kann es sein, dass beim Anzeigen eines anderen Sets kurz das
   //    vorherige angezeigt wird, ehe das Laden des angeforderten LegoSets die Daten im State leeren. Daher prüfen wir
   //    hier, ob das zwischengespeicherte LegoSet dem angeforderten entspricht und wenn nicht, gibt es noch kein
   //    LegoSet zum anzuzeigen.
   const legoSet =
      legoSetFromState?.number === numberFromRouteParams ? legoSetFromState : undefined;

   useEffect(() => {
      dispatch(showBackButton(true));
   }, [dispatch]);

   useEffect(() => {
      (async () => {
         const legoSets = await LegoSetModel.list({ number: numberFromRouteParams });
         dispatch(loadLegoSet(legoSets[0]?.id ?? 0));
      })();
   }, [dispatch, numberFromRouteParams]);

   if (legoSet === undefined || loading) return <ContentLoader />; // LegoSet ist noch nicht geladen worden
   if (!legoSet && !loading) return <PageNotFoundPage />; // LegoSet wurde versucht zu laden, es existiert nur nicht

   return (
      <Container>
         <Row>
            <Col>
               <PageTitle
                  title={`${legoSet?.number ?? ''}: ${legoSet?.name ?? ''}`}
                  appTitle={legoSet?.number}
                  subtitle={legoSet?.name}
               >
                  <ContentWithPlaceholder isLoading={!legoSet} size="h1" width="60%">
                     {legoSet?.number}: {legoSet?.name}
                  </ContentWithPlaceholder>
               </PageTitle>
               {legoSet?.retired && (
                  <Alert variant="info">
                     <FormattedMessage
                        id="lego-set.inventory.retired-by-lego"
                        defaultMessage="Dieses Set wird von LEGO offiziell nicht mehr verkauft."
                     />
                  </Alert>
               )}
            </Col>
         </Row>
         <Row>
            <Col>
               <Tags legoSet={legoSet} />
            </Col>
         </Row>
         <Row className={isMobile ? '' : 'mb-4'}>
            <Col xs={12} lg={6} xl={5}>
               <LegoSetPropertiesTable legoSet={legoSet} />
            </Col>
            <Col xs={12} lg={6} xl={7}>
               <Row>
                  <Col>
                     <PriceCard legoSet={legoSet} />
                  </Col>
               </Row>
               <Row>
                  <Col>
                     <Card orientation="column">
                        <LegoSetPriceChart legoSetId={legoSet?.id} />
                     </Card>
                  </Col>
               </Row>
               <Row>
                  <Col className="d-flex flex-fill gap-2">
                     <ShippingCostsDialogButton
                        size={legoSet?.size}
                        weight={legoSet?.weight}
                        avgMarketPrice={legoSet?.statistics?.currentMarketValue?.avg}
                     />
                     <PriceCalculatorDialogButton />
                  </Col>
               </Row>
               <Row>
                  <Col className="d-flex flex-fill">
                     <ResponsiveButton
                        variant="success"
                        className="mt-2 mb-3 d-flex flex-fill gap-1 justify-content-center align-items-center"
                        to={isMobile ? pageLinks.editInventory(legoSet) : undefined}
                        onClick={
                           !isMobile
                              ? () => {
                                   setInventoryToEdit({
                                      ...newInventoryObject,
                                      set_id: legoSet?.id,
                                   });
                                }
                              : undefined
                        }
                     >
                        <Icon path={mdiPlusThick} color={Colors.white} size={1.25} />
                        <FormattedMessage
                           id="button.add-inventory"
                           defaultMessage="Zum Inventar hinzufügen"
                        />
                     </ResponsiveButton>
                  </Col>
               </Row>
            </Col>
         </Row>
         {!legoSet ? (
            <ContentLoader />
         ) : (
            <Row>
               <Col xs={12}>
                  <InventoryList
                     title={intl.formatMessage({
                        id: 'lego-set.inventory.wanted.title',
                        defaultMessage: 'Meine Wunschliste',
                     })}
                     type="wanted"
                  />
               </Col>
               <Col xs={12}>
                  <InventoryList
                     title={intl.formatMessage({
                        id: 'lego-set.inventory.bought.title',
                        defaultMessage: 'Meine Inventar',
                     })}
                     type="bought"
                  />
               </Col>
               <Col xs={12}>
                  <InventoryList
                     title={intl.formatMessage({
                        id: 'lego-set.inventory.sold.title',
                        defaultMessage: 'Meine Verkäufe',
                     })}
                     type="sold"
                  />
               </Col>
            </Row>
         )}

         <EditInventoryDialog
            show={!!inventoryToEdit}
            setId={legoSet?.id ?? 0}
            inventory={inventoryToEdit ?? {}}
            onClose={() => setInventoryToEdit(undefined)}
         />
      </Container>
   );
};
