import React, { useCallback, useState } from 'react';
import { isMobile } from 'react-device-detect';
import { Container, Form, Modal } from 'react-bootstrap';
import { FormattedMessage } from 'react-intl';
import { Formik } from 'formik';
import { toast } from 'react-toastify';
import { Colors } from '../atoms';
import { updateSessionUser } from '../../redux/appSlice';
import { FormPartPublicName, useValidation } from '../parts/FormPartPublicName';
import { ButtonPanel, LoadingButton, ResponsiveButton } from '../molecules';
import { useAppDispatch, useMemorizedIntl, useSession } from '../../hooks';
import { User } from '../../types/api';
import { UserModel } from '../../models/UserModel';
import { SubmitButton } from '../forms';

export const MissingPublicNameBanner = () => {
   const { sessionUser } = useSession();
   const [showDialog, setShowDialog] = useState(false);

   if (!sessionUser || sessionUser.public_name || !sessionUser.registration_completed) {
      // User ist nicht angemeldet oder hat bereits einen Namen vergeben.
      return null;
   }

   return (
      <div style={{ backgroundColor: Colors.warning }}>
         <SetPublicNameDialog show={showDialog} onClose={() => setShowDialog(false)} />
         <Container className="py-2">
            <FormattedMessage
               id="banner.missing-public-name.text"
               defaultMessage="Hallo <strong>{user}</strong>.{br}{br}In naher Zukunft wird es einige Funktionen geben, für die du als Benutzer von Brickfolio.de sichtbar sein wirst (z.B. Kommentare im Blog). Daher ist es notwendig, dass du für deinen Account einen Nickname vergibst."
               values={{
                  br: <br />,
                  user: sessionUser.name,
               }}
            />
            <div className="pt-3 d-flex justify-content-center">
               <LoadingButton onClick={() => setShowDialog(true)}>
                  <FormattedMessage
                     id="banner.missing-public-name.set-name-button"
                     defaultMessage="Klicke hier, um deinen Nickname zu setzen"
                  />
               </LoadingButton>
            </div>
         </Container>
      </div>
   );
};

interface DialogProps {
   show: boolean;
   onClose: () => void;
}

const SetPublicNameDialog = (props: DialogProps) => {
   const intl = useMemorizedIntl();
   const dispatch = useAppDispatch();
   const handleValidate = useValidation();
   const { sessionUser } = useSession();

   const handleSubmitForm = useCallback(
      async (values: User) => {
         const user = await UserModel.update(values);
         dispatch(updateSessionUser(user));
         toast.success(
            intl.formatMessage({
               id: 'dialog.set-public-name.success-toast.text',
               defaultMessage: 'Du hast deinen Nickname erfolgreich gesetzt.',
            })
         );
         props.onClose();
      },
      [dispatch, intl, props]
   );

   if (!sessionUser) return null;

   return (
      <Modal show={props.show} onHide={props.onClose} centered={isMobile}>
         <Formik
            onSubmit={handleSubmitForm}
            initialValues={sessionUser}
            validate={handleValidate}
            enableReinitialize
         >
            {formik => (
               <Form
                  noValidate
                  onSubmit={e => {
                     e.preventDefault();
                     formik.handleSubmit();
                  }}
               >
                  <Modal.Header closeButton>
                     <Modal.Title>
                        <FormattedMessage
                           id="dialog.set-public-name.title"
                           defaultMessage="Gib deinen Nickname ein"
                        />
                     </Modal.Title>
                  </Modal.Header>

                  <Modal.Body>
                     <FormPartPublicName formik={formik} />
                  </Modal.Body>

                  <Modal.Footer>
                     <ButtonPanel>
                        <ResponsiveButton variant="secondary" onClick={props.onClose}>
                           <FormattedMessage id="button.cancel" defaultMessage="Abbrechen" />
                        </ResponsiveButton>
                        <SubmitButton formik={formik}>
                           <FormattedMessage id="button.save" defaultMessage="Speichern" />
                        </SubmitButton>
                     </ButtonPanel>
                  </Modal.Footer>
               </Form>
            )}
         </Formik>
      </Modal>
   );
};
