import React, { useEffect, useState } from 'react';
import { Badge, Container, ListGroup } from 'react-bootstrap';
import { mdiPlusThick } from '@mdi/js';
import Icon from '@mdi/react';
import { isMobile } from 'react-device-detect';
import { Link } from 'react-router-dom';
import { mdiChevronRight } from '@mdi/js/commonjs/mdi';
import { showBackButton } from '../../../redux/appSlice';
import { LinkButton } from '../../../components/molecules';
import { Colors } from '../../../components/atoms';
import { CategoryModel } from '../../../models/CategoryModel';
import { Category } from '../../../types/api';
import { useAppDispatch } from '../../../hooks';
import { PageTitle } from '../../../components/organisms/PageTitle';

export const CategoriesAdminPage = () => {
   const dispatch = useAppDispatch();
   const [categories, setCategories] = useState<Category[] | null>(null);

   useEffect(() => {
      dispatch(showBackButton(true));
   }, [dispatch]);

   useEffect(() => {
      (async () => {
         setCategories(await CategoryModel.list({}, 'name', 'ASC'));
      })();
   }, []);

   return (
      <Container>
         <PageTitle title="Kategorien" subtitle="Administration" />
         <p>
            <LinkButton to="/admin/categories/0" variant="success" block={isMobile}>
               <span className="d-flex justify-content-center align-items-center">
                  <Icon
                     path={mdiPlusThick}
                     color={Colors.white}
                     size={1}
                     title="Neue Kategorie hinzufügen"
                  />
                  &nbsp;Neue Kategorie hinzufügen
               </span>
            </LinkButton>
         </p>
         <ListGroup>
            {categories?.map(c => (
               <ListGroup.Item
                  key={c.id}
                  as={Link}
                  to={`/admin/categories/${c.id}`}
                  className="d-flex flex-row"
                  action
               >
                  <span className="flex-fill">{c.name}</span>
                  <Badge className="badge-lg ms-2" pill bg="secondary">
                     {c.legoSets.length}
                  </Badge>
                  <div className="d-flex align-items-center">
                     <Icon path={mdiChevronRight} color={Colors.link} size={1} />
                  </div>
               </ListGroup.Item>
            ))}
         </ListGroup>
      </Container>
   );
};
