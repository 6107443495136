import { useLocation, useNavigate } from 'react-router-dom';
import { useGoogleLogout } from 'react-google-login';
import { useCallback } from 'react';
import config from '../utils/config';
import { setLoggedIn, setLoggedOut } from '../redux/appSlice';
import { UserModel } from '../models/UserModel';
import { User } from '../types/api';
import { setCategories, setLabels } from '../redux/dataSlice';
import { CategoryModel } from '../models/CategoryModel';
import { LabelModel } from '../models/LabelModel';
import { useAppSelector } from './useAppSelector';
import { useAppDispatch } from './useAppDispatch';

export const useSession = () => {
   const dispatch = useAppDispatch();
   const navigate = useNavigate();
   const location = useLocation();

   const {
      user: sessionUser,
      roles: sessionRoles,
      loginState,
   } = useAppSelector(s => s.app.session);

   const { signOut: googleSignOut } = useGoogleLogout({
      clientId: config.googleClientId,
      onLogoutSuccess: async () => {
         await UserModel.logout();
         dispatch(setLoggedOut());
         navigate('/', { replace: true });
      },
      onFailure: () => {
         // TODO: Implementieren
      },
   });

   const loadAndCacheData = useCallback(
      async (loggedInUser: User) => {
         // Kategorien laden und für Menü setzen
         const categories = await CategoryModel.list();
         dispatch(setCategories(categories));

         // Labels laden und für Menü setzen
         dispatch(setLabels(await LabelModel.list()));
      },
      [dispatch]
   );

   const signIn = useCallback(
      async (loggedInUser: User) => {
         await loadAndCacheData(loggedInUser);
         dispatch(setLoggedIn(loggedInUser));
         navigate(location, { replace: true });
      },
      [dispatch, loadAndCacheData, navigate, location]
   );

   return {
      sessionUser: sessionUser,
      sessionRoles: sessionRoles,
      loginState: loginState,
      signIn: signIn,
      signOut: googleSignOut,
   };
};
